import React, { forwardRef, useImperativeHandle } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import PropTypes from "prop-types";

import { st_escolaridade } from "../../../services/constants.js";
import { FormFieldsGrid } from "../DynamicForm.js";

const FormEscolaridade = forwardRef(({ defaultValues }, ref) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({
    defaultValues: defaultValues || {
      escolaridades: [
        {
          escolaridade: "",
          situacao: "",
          curso: "",
          conclusao: "",
          instituicaoEnsino: "",
        },
      ],
    },
    mode: "onBlur",
  });

  const { fields, append } = useFieldArray({
    control,
    name: "escolaridades",
  });

  const handleFormSubmit = data => {
    console.log("Form data:", data);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      console.log("SUBMIT FORM ESCOLARIDADE");
      handleSubmit(handleFormSubmit)();
    },
    getFormValues: async () => {
      const isValid = await trigger();
      const values = getValues();
      const currentErrors = errors;

      return {
        values: { ...values },
        errors: isValid ? null : currentErrors,
      };
    },
  }));

  const escolaridadeFields = [
    {
      name: "escolaridade",
      label: "Escolaridade / Grau de instrução",
      validation: { required: "Escolaridade é obrigatória" },
      renderComponent: ({ value, onChange, error }) => (
        <FormControl
          fullWidth
          error={!!error}
        >
          <InputLabel>Escolaridade / Grau de instrução</InputLabel>
          <Select
            value={value}
            onChange={onChange}
            label="Escolaridade / Grau de instrução"
          >
            <MenuItem value="">Selecione a Escolaridade</MenuItem>
            {Object.entries(st_escolaridade).map(([value, label]) => (
              <MenuItem
                key={value}
                value={value}
              >
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "situacao",
      label: "Situação",
      renderComponent: ({ value, onChange }) => (
        <TextField
          value={value}
          onChange={onChange}
          label="Situação"
          fullWidth
        />
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "curso",
      label: "Curso",
      renderComponent: ({ value, onChange }) => (
        <TextField
          value={value}
          onChange={onChange}
          label="Curso"
          fullWidth
        />
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "conclusao",
      label: "Conclusão",
      renderComponent: ({ value, onChange }) => (
        <TextField
          value={value}
          onChange={onChange}
          label="Conclusão"
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "instituicaoEnsino",
      label: "Instituição de ensino",
      renderComponent: ({ value, onChange }) => (
        <TextField
          value={value}
          onChange={onChange}
          label="Instituição de ensino"
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6 },
    },
  ];

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      {fields.map((field, index) => (
        <React.Fragment key={field.id}>
          <FormFieldsGrid
            fields={escolaridadeFields.map(f => ({
              ...f,
              name: `escolaridades.${index}.${f.name}`,
            }))}
            control={control}
            errors={errors}
          />
          <Grid
            item
            xs={12}
            sx={{ mt: 3, mb: 3 }}
          >
            <div style={{ borderBottom: "1px solid LightGrey" }}></div>
          </Grid>
        </React.Fragment>
      ))}
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            variant="outlined"
            onClick={() =>
              append({
                escolaridade: "",
                situacao: "",
                curso: "",
                conclusao: "",
                instituicaoEnsino: "",
              })
            }
            sx={{ mt: 2 }}
            startIcon={<AddCircleIcon />}
          >
            ADICIONAR ESCOLARIDADE
          </Button>
        </Grid>
      </Grid>
    </form>
  );
});

FormEscolaridade.displayName = "FormEscolaridade";

FormEscolaridade.propTypes = {
  defaultValues: PropTypes.shape({
    escolaridades: PropTypes.arrayOf(
      PropTypes.shape({
        escolaridade: PropTypes.string,
        situacao: PropTypes.string,
        curso: PropTypes.string,
        conclusao: PropTypes.string,
        instituicaoEnsino: PropTypes.string,
      }),
    ),
  }),
};

export default FormEscolaridade;
