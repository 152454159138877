import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Container } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import LoadingComponent from "../../components/loading/LoadingComponent";
import DefaultTable from "../../components/table/DefaultTable";
import TitlePage from "../../components/title-page/TitlePage";
import adminService from "../../services/admin.service";

const PageVisualizarAlteracoesDocumentos = () => {
  //const navigate = useNavigate();
  let { id } = useParams();
  // TODO: remover esse default

  const [documentos, setDocumentos] = useState([]);

  // Conexão com o back-end
  const {
    isPending,
    isError,
    data: files,
    error,
  } = useQuery({
    queryKey: ["arquivosEmpresaParceira", id],
    queryFn: ({ queryKey }) => {
      const [, id] = queryKey;
      return adminService.getDocumentosEmpresaParceira(id);
    },
    enabled: !!id,
  });

  if (isError) {
    console.error("Erro de carregamento: ", error);
  }

  useEffect(() => {
    // console.log("files", files);
    if (files) {
      setDocumentos(Array.isArray(files) ? files : []);
    }
  }, [files]);

  const colunas = [
    { field: "dataModificacao", headerName: "Data", sxProps: { width: "200px" } },
    { field: "momento", headerName: "Momento", sxProps: { width: "200px" } },
    { field: "nomeArquivo", headerName: "Nome do Arquivo", sxProps: { width: "200px" } },
  ];

  const handleDownload = (fileData, defaultFileName) => {
    // Prompt user for file name
    const fileName = prompt("Escolha um nome para o arquivo", defaultFileName) || defaultFileName;

    // Create a Blob URL and download the file
    const url = window.URL.createObjectURL(new Blob([fileData]));
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const getTabelaActions = row => {
    const matchingDocumento = documentos.find(doc => doc.nomeArquivo === row.nomeArquivo);

    return [
      {
        title: "Download",
        icon: "download",
        href: "",
        onClick: () => {
          if (matchingDocumento) {
            handleDownload(matchingDocumento.base64, matchingDocumento.nomeArquivo);
          } else {
            console.log("No matching document found for this row.");
          }
        },
      },
    ];
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/parceiro/listar/pendentes", text: "Listar Parceiros Pendentes" },
          { href: "/parceiro/analisar/", text: "Analisar Parceiro Pendente" },
        ]}
        currentPage="Informações alteradas"
      />

      <TitlePage title={"Anexos na aprovação e reprovação"} />

      {isPending ? (
        <LoadingComponent
          isLoading={isPending}
          widescreen={true}
          fullScreen={false}
        />
      ) : isError || !id ? (
        <ErroCarregamentoModal isShow={true} />
      ) : (
        <DefaultTable
          columns={colunas}
          rows={documentos}
          actionButtons={getTabelaActions}
        />
      )}
    </Container>
  );
};

export default PageVisualizarAlteracoesDocumentos;
