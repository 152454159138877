import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import { documentFileType } from "../../services/constants.js";
import CustomDatePicker from "../formularios/CustomDatePicker";
import { formatCNPJ, formatTelefone } from "../formularios/utils.js";
import FileUpload from "../inputs/fileUpload";

const STATUS_PENDING = "";
const STATUS_REJECTED = "reprovado";
const STATUS_APPROVED = "aprovado";

const determineInitialStyles = status => {
  // Normalize the status for comparison
  const normalizedStatus = status?.trim().toLowerCase();

  switch (normalizedStatus) {
    case STATUS_REJECTED.toLowerCase():
      return {
        bgcolor: "rgba(236, 186, 67, 0.48)",
        borderColor: "orange",
        textColor: "orange",
        statusText: "Reprovado",
      };
    case STATUS_APPROVED.toLowerCase():
      return {
        bgcolor: "rgba(0, 255, 0, 0.1)",
        borderColor: "green",
        textColor: "green",
        statusText: "Aprovado",
      };
    case STATUS_PENDING.toLowerCase():
    default:
      return {
        bgcolor: "rgba(255, 0, 0, 0.1)",
        borderColor: "red",
        textColor: "red",
        statusText: "Pendente de aprovação",
      };
  }
};

const AdminAnalisarEmpresaComplementarParceiro = ({ valores, id, onSubmit }) => {
  const navigate = useNavigate();

  const [localFiles, setLocalFiles] = useState([]);
  const [status, setStatus] = useState(valores.status);
  const [styles, setStyles] = useState(determineInitialStyles(status));

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: valores,
    mode: "onSubmit",
  });

  useEffect(() => {
    setStyles(determineInitialStyles(status));
  }, [status]);

  const handleFiles = (newFiles, setFieldValue) => {
    setLocalFiles(newFiles);
    setFieldValue("anexo", newFiles);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          <Grid
            item
            xs={12}
            mb={1}
          >
            <Typography variant="subtitle2">Histórico de ativação de perfis ADM</Typography>
          </Grid>

          {/* Nome e CNPJ */}
          <Grid
            spacing={1}
            container
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormGroup>
                <TextField
                  id="nomePontoFocal"
                  name="nomePontoFocal"
                  label="Nome"
                  placeholder="Nome"
                  value={valores.nomePontoFocal}
                  type="text"
                  disabled
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormGroup>
                <TextField
                  id="cnpj"
                  name="cnpj"
                  label="CNPJ"
                  placeholder="CNPJ"
                  value={formatCNPJ(valores.cnpj) || ""}
                  type="text"
                  disabled
                />
              </FormGroup>
            </Grid>
          </Grid>

          {/* Data de Cadastro, Telefone e Ultima Modificação */}
          <Grid
            container
            spacing={1}
            mt={1}
          >
            <Grid
              item
              xs={12}
              sm={4}
            >
              <CustomDatePicker
                id="dataCadastro"
                name="dataCadastro"
                label="Data do cadastro"
                placeholder="Data do cadastro"
                value={dayjs(valores.dataCadastro)}
                format="DD/MM/YYYY"
                disabled
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <FormGroup>
                <TextField
                  id="telefone"
                  name="telefone"
                  label="Telefone"
                  placeholder="Telefone"
                  value={formatTelefone(valores.telefone) || ""}
                  type="text"
                  disabled
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <CustomDatePicker
                label="Última Modificação"
                id="dataUltimaModificacao"
                name="dataUltimaModificacao"
                value={dayjs(valores.dataUltimaModificacao)}
                format="DD/MM/YYYY"
                disabled
              />
            </Grid>
          </Grid>

          {/* Status e Botão Pendente/Aprovar */}
          <Grid
            container
            spacing={1}
            mt={1}
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              sm={9}
            >
              <Controller
                name="status"
                control={control}
                rules={{ required: "Status é obrigatório" }}
                render={({ field: { value, onChange } }) => (
                  <FormControl fullWidth>
                    <InputLabel id="label-status">Status do Parceiro</InputLabel>
                    <Select
                      id="status"
                      labelId="label-status"
                      value={value ?? ""}
                      onChange={e => {
                        onChange(e);
                        setStatus(e.target.value); // Update status when value changes
                      }}
                      error={!!errors.status}
                    >
                      <MenuItem
                        value={""}
                        disabled
                      >
                        Altere o Status do Parceiro
                      </MenuItem>
                      <MenuItem value="reprovado">Reprovar</MenuItem>
                      <MenuItem value="aprovado">Aprovar</MenuItem>
                    </Select>
                    {errors.status && <FormHelperText error>{errors.status.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
            >
              <Box
                py={1}
                px={1}
                alignContent="center"
                sx={{
                  bgcolor: styles.bgcolor,
                  border: "2px solid",
                  borderColor: styles.borderColor,
                  borderRadius: "4px",
                }}
              >
                <Typography
                  align="center"
                  sx={{
                    color: styles.textColor,
                    fontFamily: "Rawline Bold",
                  }}
                >
                  {styles.statusText}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          {/* Botão Visualizar Informações Complementares */}
          <Grid
            container
            spacing={1}
            mt={1}
            justifyContent="flex-end"
          >
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => navigate("/parceiro/empresa-complementar/visualizar/" + id)}
              >
                <Typography
                  variant={"BUTTON TEXT"}
                  sx={{
                    whiteSpace: "wrap",
                    minWidth: "auto",
                  }}
                >
                  VISUALIZAR INFORMAÇÕES COMPLEMENTARES
                </Typography>
              </Button>
            </Grid>
          </Grid>

          {/* Motivo */}
          <Grid
            container
            mt={1}
            spacing={1}
          >
            <Grid
              item
              xs={12}
            >
              <Controller
                name="motivo"
                control={control}
                rules={{ required: "Motivo é obrigatório" }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    id="motivo"
                    label="Motivo"
                    placeholder="Motivo"
                    value={value}
                    onChange={onChange}
                    error={!!errors.motivo}
                    helperText={errors.motivo?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Card>

        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          {/* Anexar Documentos */}
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
            >
              <Typography variant="subtitle2">Anexar Documentos</Typography>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Controller
                name="arquivos"
                control={control}
                render={() => (
                  <FileUpload
                    initialValues={localFiles}
                    onFieldsChange={fields => handleFiles(fields, setValue)}
                    fieldLabel={"Anexar Documentos"}
                    showFiles={true}
                    fileType={documentFileType}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Card>

        {/* Botões */}
        <Grid
          container
          spacing={1}
          mt={3}
          justifyContent="flex-end"
        >
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => navigate("/parceiro/listar/pendentes")}
            >
              <Typography variant={"BUTTON TEXT"}>CANCELAR</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => navigate("/parceiro/empresa-complementar/visualizar/" + id)}
            >
              <Typography
                variant={"BUTTON TEXT"}
                sx={{
                  whiteSpace: "wrap",
                  minWidth: "auto",
                }}
              >
                VISUALIZAR INFORMAÇÕES COMPLEMENTARES
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              fullWidth
              sx={{ gap: "8px" }}
            >
              <SaveIcon fontSize="small" />
              <Typography variant={"BUTTON TEXT"}>SALVAR</Typography>
            </LoadingButton>
          </Grid>
        </Grid>
      </Stack>
    </form>
  );
};

AdminAnalisarEmpresaComplementarParceiro.propTypes = {
  valores: PropTypes.object,
  id: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default AdminAnalisarEmpresaComplementarParceiro;
