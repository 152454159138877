import React, { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { Button, FormControl, FormGroup, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { tp_abrangencia } from "../../services/constants.js";
import optionsService from "../../services/options.service.js";
import CustomDatePicker from "../formularios/CustomDatePicker";
import { SelectCidade } from "./fields/SelectCidade.js";
import { SelectUF } from "./fields/SelectUF.js";
import { formatCEP, formatTelefone } from "./utils.js";

let defaultValues = {
  cep: "",
  endereco: "",
  numero: "",
  complemento: "",
  bairro: "",
  uf: "",
  municipio: "",
  telefone: "",
  vagas: "",
  abrangencia: "",
  dataInicioCurso: "",
  dataFinalCurso: "",
};

const FormAddressSchool = ({ onChange, values = [defaultValues], showError = false }) => {
  const [formValues, setFormValues] = useState(values);
  const [ufs, setUfs] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [selectedUf, setSelectedUf] = useState([]);
  const [selectedMunicipio, setSelectedMunicipio] = useState([]);
  const [errors, setErrors] = useState(
    values.map(() => ({
      cep: "",
      endereco: "",
      numero: "",
      bairro: "",
      uf: "",
      municipio: "",
      telefone: "",
      vagas: "",
      abrangencia: "",
      dataInicioCurso: "",
      dataFinalCurso: "",
    })),
  );

  useEffect(() => {
    const fetchUfs = async () => {
      try {
        const ufsData = await optionsService.getAllUFs();
        setUfs(ufsData);
      } catch (error) {
        console.error("Erro ao obter UFs:", error);
      }
    };
    fetchUfs();
  }, []);

  const fetchCidadesByUf = async (ufSigla, setCidadesFunction) => {
    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidadesFunction(cidadesData);
    } catch (error) {
      console.error("Erro ao obter cidades:", error);
    }
  };

  const handleSelectUf = (index, event) => {
    const { name, value } = event.target;

    setFormValues(prevFormValues => {
      const newFormValues = [...prevFormValues];
      newFormValues[index] = {
        ...newFormValues[index],
        [name]: value,
        municipio: "",
      };
      onChange(newFormValues);
      return newFormValues;
    });

    setSelectedUf(prevSelectedUf => {
      const updatedSelectedUf = [...prevSelectedUf];
      updatedSelectedUf[index] = value;
      return updatedSelectedUf;
    });

    fetchCidadesByUf(value, cidades => {
      setMunicipios(prevMunicipios => {
        const updatedMunicipios = [...prevMunicipios];
        updatedMunicipios[index] = cidades;
        return updatedMunicipios;
      });
    });
  };

  const handleSelectMunicipio = (index, event) => {
    const { name, value } = event.target;
    setFormValues(prevFormValues => {
      const newFormValues = [...prevFormValues];
      newFormValues[index] = {
        ...newFormValues[index],
        [name]: value,
      };
      console.log(`nes form - `, newFormValues);
      onChange(newFormValues);
      return newFormValues;
    });

    setSelectedMunicipio(prevSelectedMunicipio => {
      const updatedSelectedMunicipio = [...prevSelectedMunicipio];
      updatedSelectedMunicipio[index] = value;
      return updatedSelectedMunicipio;
    });

    console.log("updatedSelectedMunicipio - ", selectedMunicipio);
  };

  const handleChange = (index, event, isDate = false) => {
    const name = isDate ? event : event.target.name;
    const value = isDate ? event : event.target.value;

    const newFormValues = [...formValues];
    newFormValues[index][name] = value;

    setFormValues(newFormValues);
    validateField(index, name, value);
    onChange(newFormValues);
  };

  const handleChangeCEP = async (index, event) => {
    const { value } = event.target;
    const newFormValues = [...formValues];
    newFormValues[index].cep = value;

    setFormValues(newFormValues);

    if (value.replace(/\D/g, "").length === 8) {
      try {
        const dados = await optionsService.verificarCEP(value);
        if (dados) {
          newFormValues[index] = {
            ...newFormValues[index],
            uf: dados.uf,
            endereco: dados.logradouro,
            bairro: dados.bairro,
            complemento: dados.complemento || "",
            municipio: dados.localidade,
          };

          // Validar os campos preenchidos automaticamente
          validateField(index, "uf", dados.uf);
          validateField(index, "endereco", dados.logradouro);
          validateField(index, "bairro", dados.bairro);
          validateField(index, "municipio", dados.localidade);
          validateField(index, "cep", dados.cep);

          const updatedSelectedUf = [...selectedUf];
          updatedSelectedUf[index] = dados.uf;
          const updatedSelectedMunicipio = [...selectedMunicipio];
          updatedSelectedMunicipio[index] = dados.localidade;

          setSelectedUf(updatedSelectedUf);
          setSelectedMunicipio(updatedSelectedMunicipio);
          setFormValues(newFormValues);
          onChange(newFormValues);

          fetchCidadesByUf(dados.uf, cidades => {
            const updatedMunicipios = [...municipios];
            updatedMunicipios[index] = cidades;
            setMunicipios(updatedMunicipios);
          });
        }
      } catch (error) {
        console.error("Erro obtendo CEP", error);
      }
    }
  };

  const addNewAddressForm = () => {
    setFormValues([
      ...formValues,
      {
        cep: "",
        endereco: "",
        numero: "",
        complemento: "",
        bairro: "",
        uf: "",
        municipio: "",
        telefone: "",
        vagas: "",
        abrangencia: "",
        dataInicioCurso: "",
        dataFinalCurso: "",
      },
    ]);
  };

  const removeAddressForm = index => {
    const newFormValues = formValues.filter((_, i) => i !== index);
    setFormValues(newFormValues);
    onChange(newFormValues);
  };

  // Função para validar campos
  const validateField = (index, name, value) => {
    const newErrors = [...errors];

    switch (name) {
      case "cep":
        newErrors[index].cep = !value ? "CEP é obrigatório" : !/^\d{5}-?\d{3}$/.test(value) ? "CEP inválido" : "";
        break;
      case "endereco":
        newErrors[index].endereco = !value ? "Endereço é obrigatório" : "";
        break;
      case "numero":
        newErrors[index].numero = !value ? "Número é obrigatório" : "";
        break;
      case "bairro":
        newErrors[index].bairro = !value ? "Bairro é obrigatório" : "";
        break;
      case "uf":
        newErrors[index].uf = !value ? "UF é obrigatória" : "";
        break;
      case "municipio":
        newErrors[index].municipio = !value ? "Município é obrigatório" : "";
        break;
      case "telefone":
        newErrors[index].telefone = !value
          ? "Telefone é obrigatório"
          : !/^\(\d{2}\) \d{4,5}-\d{4}$/.test(formatTelefone(value))
            ? "Telefone inválido"
            : "";
        break;
      case "vagas":
        newErrors[index].vagas = !value
          ? "Número de vagas é obrigatório"
          : isNaN(value) || value <= 0
            ? "Número de vagas deve ser maior que zero"
            : "";
        break;
      case "abrangencia":
        newErrors[index].abrangencia = !value ? "Abrangência é obrigatória" : "";
        break;
      case "dataInicioCurso":
        newErrors[index].dataInicioCurso = !value ? "Data de início é obrigatória" : "";
        break;
      case "dataFinalCurso":
        newErrors[index].dataFinalCurso = !value ? "Data final é obrigatória" : "";
        break;
      default:
        break;
    }

    setErrors(newErrors);
  };

  // Função para validar todo o formulário
  const validateForm = () => {
    const newErrors = formValues.map(address => {
      const addressErrors = {};

      // Validar cada campo
      if (!address.cep) addressErrors.cep = "CEP é obrigatório";
      if (!address.endereco) addressErrors.endereco = "Endereço é obrigatório";
      if (!address.numero) addressErrors.numero = "Número é obrigatório";
      if (!address.bairro) addressErrors.bairro = "Bairro é obrigatório";
      if (!address.uf) addressErrors.uf = "UF é obrigatória";
      if (!address.municipio) addressErrors.municipio = "Município é obrigatório";
      if (!address.telefone) addressErrors.telefone = "Telefone é obrigatório";
      if (!address.vagas) addressErrors.vagas = "Número de vagas é obrigatório";
      if (!address.abrangencia) addressErrors.abrangencia = "Abrangência é obrigatória";
      if (!address.dataInicioCurso) addressErrors.dataInicioCurso = "Data de início é obrigatória";
      if (!address.dataFinalCurso) addressErrors.dataFinalCurso = "Data final é obrigatória";

      return addressErrors;
    });

    setErrors(newErrors);
    return newErrors.every(addressErrors => Object.keys(addressErrors).length === 0);
  };

  // Adicione um useEffect para receber o comando de submit do pai
  useEffect(() => {
    validateForm();
  }, [showError]);

  // Modificar as funções de erro para considerar showError
  const hasError = (index, fieldName) => {
    return showError && errors[index]?.[fieldName] ? true : false;
  };

  const getErrorMessage = (index, fieldName) => {
    return showError ? errors[index]?.[fieldName] || "" : "";
  };

  return (
    <>
      {formValues.map((address, index) => (
        <Grid
          container
          spacing={2}
          key={index}
        >
          <Grid
            item
            xs={12}
            mb={-2}
          >
            <Typography
              variant="h5"
              sx={{ fontSize: "12", mb: "16px", fontFamily: "Rawline Regular", color: "grey" }}
            >
              Endereço {index + 1}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <CustomDatePicker
              label="*Inicio do curso"
              id={`dataInicioCurso-${index}`}
              name="dataInicioCurso"
              format="DD/MM/YYYY"
              value={address.dataInicioCurso || null}
              onChange={newValue => {
                handleChange(index, "dataInicioCurso", true);
                const newFormValues = [...formValues];
                newFormValues[index].dataInicioCurso = newValue;
                setFormValues(newFormValues);
              }}
              color={hasError(index, "dataInicioCurso") ? "error" : "primary"}
              error={hasError(index, "dataInicioCurso")}
              helperText={getErrorMessage(index, "dataInicioCurso")}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <CustomDatePicker
              label="*Final do curso"
              id={`dataFinalCurso-${index}`}
              name="dataFinalCurso"
              format="DD/MM/YYYY"
              value={address.dataFinalCurso || null}
              onChange={newValue => {
                handleChange(index, "dataFinalCurso", true);
                const newFormValues = [...formValues];
                newFormValues[index].dataFinalCurso = newValue;
                setFormValues(newFormValues);
              }}
              color={hasError(index, "dataFinalCurso") ? "error" : "primary"}
              error={hasError(index, "dataFinalCurso")}
              helperText={getErrorMessage(index, "dataFinalCurso")}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
          >
            <FormGroup>
              <TextField
                label="*CEP"
                id={`cep-${index}`}
                name="cep"
                value={formatCEP(address.cep)}
                onChange={e => handleChangeCEP(index, e)}
                inputProps={{ maxLength: 10 }}
                error={hasError(index, "cep")}
                helperText={getErrorMessage(index, "cep")}
                color={hasError(index, "cep") ? "error" : "primary"}
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
          >
            <FormGroup>
              <TextField
                label="*Endereço"
                id={`endereco-${index}`}
                name="endereco"
                value={address.endereco}
                onChange={e => handleChange(index, e)}
                error={hasError(index, "endereco")}
                helperText={getErrorMessage(index, "endereco")}
                color={hasError(index, "endereco") ? "error" : "primary"}
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
          >
            <FormGroup>
              <TextField
                label="*Número"
                id={`numero-${index}`}
                name="numero"
                type="number"
                value={address.numero}
                onChange={e => handleChange(index, e)}
                error={hasError(index, "numero")}
                helperText={getErrorMessage(index, "numero")}
                color={hasError(index, "numero") ? "error" : "primary"}
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormGroup>
              <TextField
                label="Complemento"
                id={`complemento-${index}`}
                name="complemento"
                value={address.complemento}
                onChange={e => handleChange(index, e)}
                error={hasError(index, "complemento")}
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormGroup>
              <TextField
                label="*Bairro"
                id={`bairro-${index}`}
                name="bairro"
                value={address.bairro}
                onChange={e => handleChange(index, e)}
                error={hasError(index, "bairro")}
                helperText={getErrorMessage(index, "bairro")}
                color={hasError(index, "bairro") ? "error" : "primary"}
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormGroup>
              <SelectUF
                idSelect={`uf-${index}`}
                nameSelect="uf"
                value={address.uf}
                ufs={ufs}
                uf={selectedUf[index]}
                label="*UF"
                color={hasError(index, "uf") ? "error" : "primary"}
                error={hasError(index, "uf")}
                handleSelectUf={e => handleSelectUf(index, e)}
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormGroup>
              <SelectCidade
                idSelect={`municipio-${index}`}
                nameSelect="municipio"
                value={address.municipio}
                cidades={municipios[index] || []}
                cidade={selectedMunicipio[index]}
                label="*Município"
                color={hasError(index, "municipio") ? "error" : "primary"}
                error={hasError(index, "municipio")}
                handleSelectCidade={e => handleSelectMunicipio(index, e)}
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormGroup>
              <TextField
                label="*Telefone"
                id={`telefone-${index}`}
                name="telefone"
                value={formatTelefone(address.telefone)}
                onChange={e => handleChange(index, e)}
                inputProps={{ maxLength: 15 }}
                error={hasError(index, "telefone")}
                helperText={getErrorMessage(index, "telefone")}
                color={hasError(index, "telefone") ? "error" : "primary"}
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormGroup>
              <TextField
                label="*Vagas"
                id={`vagas-${index}`}
                name="vagas"
                value={address.vagas}
                onChange={e => handleChange(index, e)}
                error={hasError(index, "vagas")}
                helperText={getErrorMessage(index, "vagas")}
                color={hasError(index, "vagas") ? "error" : "primary"}
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            mb={2}
          >
            <FormControl
              fullWidth
              error={hasError(index, "abrangencia")}
            >
              <InputLabel
                id={`title-label-${index}`}
                color={hasError(index, "abrangencia") ? "error" : "primary"}
              >
                *Abrangência territorial do curso
              </InputLabel>
              <Select
                labelId={`title-label-${index}`}
                id={`abrangencia-${index}`}
                name="abrangencia"
                value={address.abrangencia}
                onChange={e => handleChange(index, e)}
                defaultValue=""
                color={hasError(index, "abrangencia") ? "error" : "primary"}
              >
                {Object.keys(tp_abrangencia).map(value => (
                  <MenuItem
                    key={value}
                    value={value}
                  >
                    {tp_abrangencia[value]}
                  </MenuItem>
                ))}
              </Select>
              {hasError(index, "abrangencia") && <FormHelperText>Abrangência é obrigatória</FormHelperText>}
            </FormControl>
          </Grid>

          <Grid
            item
            xs={2}
            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginBottom: "16px" }}
          >
            <Button
              aria-label="delete"
              variant="outlined"
              onClick={() => removeAddressForm(index)}
              color="primary"
              disabled={index === 0} // Desativa para o primeiro endereço
            >
              <DeleteIcon />
            </Button>
          </Grid>
        </Grid>
      ))}
      <Button
        variant="outlined"
        color="primary"
        startIcon={<AddIcon />}
        onClick={addNewAddressForm}
      >
        Adicionar Novo Endereço
      </Button>
    </>
  );
};

FormAddressSchool.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array,
  showError: PropTypes.bool,
};

export default FormAddressSchool;
