import React, { useState } from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminListarParceirosReprovados from "../../components/filtros/AdminListarParceirosReprovados";
import TitlePage from "../../components/title-page/TitlePage";

const PageParceirosReprovados = () => {
  const [servicosModal, setServicosModal] = useState(false);
  const [informacoesModal, setInformacoesModal] = useState(false);

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        homeLink="/home"
        homeText="Admin"
        currentPage="Listar Parceiros Reprovados"
      />

      <TitlePage title={"Listar Parceiros Reprovados"} />

      <AdminListarParceirosReprovados
        servicosModal={servicosModal}
        setServicosModal={setServicosModal}
        informacoesModal={informacoesModal}
        setInformacoesModal={setInformacoesModal}
      />
    </Container>
  );
};

export default PageParceirosReprovados;
