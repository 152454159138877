import React, { useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Card,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";

import optionsService from "../../services/options.service.js";
import AprovarOuReprovarCurso from "../modals/AprovarOuReprovarCurso.js";
import InativarCurso from "../modals/InativarCurso.js";
import DefaultTable from "../table/DefaultTable.js";
import { tabelaInteressados } from "./dadosMockados.js";
import { SelectCidade } from "./fields/SelectCidade.js";
import { SelectUF } from "./fields/SelectUF.js";

const termos = {
  pcd: "PCD",
  habilidade: "Habilidade",
  experiencia: "Experiência",
  uf: "UF",
  municipio: "Município",
  microrregiao: "Em minha microrregião",
};

const tabelaColunas = [
  { field: "nome", headerName: "Nome" },
  { field: "escolaridade", headerName: "Escolaridade" },
  { field: "situacao", headerName: "Situação" },
];

const initialData = tabelaInteressados;

const initialFilterState = {
  escolaridade: "",
  uf: "",
  municipio: "",
  pcd: "Não",
  habilidade: "",
  experiencia: "",
};

const keysHidden = ["pcd", "habilidade", "experiencia", "microrregiao", "municipio", "uf"];

const ParceiroBuscarCurriculoMobilizacao = () => {
  const [inativarCurso, setInativarCurso] = useState(false);
  const [reprovarCurso, setReprovarCurso] = useState(false);
  const [filter, setFilter] = useState(initialFilterState);
  const [filteredData, setFilteredData] = useState(initialData);

  const tabelaHiddenLinhas = initialData.map(row =>
    keysHidden.reduce((acc, key) => {
      acc[key] = row[key];
      return acc;
    }, {}),
  );

  const [selectedUf, setSelectedUf] = useState("");
  const [selectedCidade, setSelectedCidade] = useState("");
  const [ufs, setUfs] = useState([]);
  const [cidades, setCidades] = useState([]);

  useEffect(() => {
    const fetchUfs = async () => {
      await optionsService
        .getAllUFs()
        .then(ufsData => {
          setUfs(ufsData);
        })
        .catch(error => {
          console.error("Erro ao obter UFs:", error);
        });
    };
    fetchUfs();
  }, []);

  const fetchCidadesByUf = async (ufSigla, setCidadesFunction) => {
    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidadesFunction(cidadesData);
    } catch (error) {
      console.error("Erro ao obter cidades:", error);
    }
  };

  const handleSelectUf = (event, setFieldValue) => {
    const { name, value } = event.target;
    setFieldValue(name, value);

    setSelectedUf(value);
    setSelectedCidade("");
    setFieldValue("cidade", "");
    fetchCidadesByUf(event.target.value, setCidades);
  };

  const handleSelectCidade = (event, setFieldValue) => {
    const { name, value } = event.target;
    setFieldValue(name, value);

    setSelectedCidade(event.target.value);
  };

  const applyFilter = () => {
    const filtered = initialData.filter(parceiro => {
      return (
        parceiro.cargaHoraria.includes(filter.cargaHoraria) ||
        parceiro.modalidade.includes(filter.modalidade) ||
        parceiro.ofertante.includes(filter.ofertante) ||
        parceiro.pesquisar.includes(filter.pesquisar) ||
        parceiro.situacao.includes(filter.situacao)
      );
    });
    setFilteredData(filtered);
  };

  const getTabelaActions = () => [
    {
      title: "Visualizar",
      href: "/parceiro/mobilizacao/curriculos/visualizar",
      icon: "visibility",
    },
    {
      title: "Interesses",
      href: "",
      icon: "description",
    },
  ];

  const resetFilter = () => {
    setFilter(initialFilterState);
    applyFilter();
  };

  const fecharInativarCurso = () => {
    setInativarCurso(false);
  };

  const fecharReprovarCurso = () => {
    setReprovarCurso(false);
  };

  const setFieldValue = (name, value) => {
    setFilter({ ...filter, [name]: value });
  };

  return (
    <>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          <Typography variant="h6">Filtro</Typography>
          {/* Filtros */}
          <Grid
            container
            spacing={2}
            mt={1}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
            >
              <FormControl fullWidth>
                <InputLabel>Escolaridade</InputLabel>
                <Select
                  id="requisitos"
                  name="requisitos"
                  value={filter.requisitos}
                  onChange={e => setFilter({ ...filter, requisitos: e.target.value })}
                >
                  <MenuItem
                    value=""
                    disabled
                  >
                    Selecione
                  </MenuItem>
                  <MenuItem value={"Fundamental"}>Fundamental</MenuItem>
                  <MenuItem value={"Médio"}>Médio</MenuItem>
                  <MenuItem value={"Superior"}>Superior</MenuItem>
                  <MenuItem value={"Mestrado"}>Mestrado</MenuItem>
                  <MenuItem value={"Doutorado"}>Doutorado</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
            >
              <SelectUF
                idSelect={"uf"}
                nameSelect={"uf"}
                value={filter.uf}
                ufs={ufs}
                uf={selectedUf}
                handleSelectUf={event => handleSelectUf(event, setFieldValue)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
            >
              <SelectCidade
                idSelect={"cidade"}
                nameSelect={"cidade"}
                label="Município"
                value={filter.municipio}
                cidades={cidades}
                cidade={selectedCidade}
                handleSelectCidade={event => handleSelectCidade(event, setFieldValue)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              display={"flex"}
              justifyContent={"center"}
            >
              <FormGroup>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                  }}
                >
                  PCD
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <Typography>Não</Typography>
                  <Switch
                    checked={filter.pcd === "Sim"}
                    onChange={e => setFilter({ ...filter, pcd: e.target.checked ? "Sim" : "Não" })}
                  />
                  <Typography>Sim</Typography>
                </Stack>
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              md={5}
            >
              <TextField
                fullWidth
                label="Habilidade"
                value={filter.habilidade}
                onChange={e => setFilter({ ...filter, habilidade: e.target.value })}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              md={5}
            >
              <TextField
                fullWidth
                label="Experiência"
                value={filter.experiencia}
                onChange={e => setFilter({ ...filter, experiencia: e.target.value })}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <FormGroup>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                  }}
                >
                  Em minha microrregião
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <Typography>Não</Typography>
                  <Switch
                    checked={filter.pcd === "Sim"}
                    onChange={e => setFilter({ ...filter, pcd: e.target.checked ? "Sim" : "Não" })}
                  />
                  <Typography>Sim</Typography>
                </Stack>
              </FormGroup>
            </Grid>
            <Grid
              container
              spacing={1}
              justifyContent="flex-end"
              mt={2}
            >
              <Grid
                item
                xs={12}
                sm={"auto"}
              >
                <Button
                  variant="outlined"
                  onClick={resetFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                  fullWidth
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={"auto"}
              >
                <Button
                  variant="contained"
                  onClick={applyFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                  fullWidth
                >
                  <SearchIcon />
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/* Tabela */}
          <Box sx={{ flexGrow: 1, mt: "40px" }}>
            <DefaultTable
              rows={filteredData}
              notFoundText={"Não foi localizado Cursos na situação pendente de aprovação!"}
              columns={tabelaColunas}
              hiddenRows={tabelaHiddenLinhas}
              termos={termos}
              mostrarCaixasSelecao={false}
              onSelecionadosChange={selecionados => {
                console.log("Custom onSelecionadosChange:", selecionados);
              }}
              actionButtons={getTabelaActions}
            ></DefaultTable>
          </Box>
        </Card>
      </Stack>
      {/* Modais */}
      <div>
        <InativarCurso
          showModal={inativarCurso}
          handleClose={fecharInativarCurso}
        />
        <AprovarOuReprovarCurso
          modalTitle="Reoprovar curso"
          showModal={reprovarCurso}
          handleClose={fecharReprovarCurso}
        />
      </div>
    </>
  );
};

export default ParceiroBuscarCurriculoMobilizacao;
