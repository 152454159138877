import React from "react";
import { Route, Routes } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import FAQ from "../../pages/FAQ";
import NotFound from "../../pages/NotFound";
import PageComponents from "../../pages/PageComponents";
import PageComponentsDefaultTable from "../../pages/PageComponentsDefaultTable";
import PageComponentsForm from "../../pages/PageComponentsForm";
import PageAcessoMicrocreditoMPO from "../../pages/parceiro/PageAcessoMicrocreditoMPO";
import PageAcessoMicrocreditoMPOAprovado from "../../pages/parceiro/PageAcessoMicrocreditoMPOAprovado";
import PageBuscarCurriculoMobilizacao from "../../pages/parceiro/PageBuscarCurriculoMobilizacao";
import PageBuscarCurriculoRede from "../../pages/parceiro/PageBuscarCurriculoRede";
import PageConsultarMPO from "../../pages/parceiro/PageConsultarMPO";
import PageCurriculoCidadao from "../../pages/parceiro/PageCurriculoCidadao";
import PageDuplicarCurso from "../../pages/parceiro/PageDuplicarCurso";
import PageDuplicarVagaTrabalho from "../../pages/parceiro/PageDuplicarVagaTrabalho";
import PageEditarCurriculoCidadao from "../../pages/parceiro/PageEditarCurriculoCidadao";
import PageEditarCurso from "../../pages/parceiro/PageEditarCurso";
import PageEditarParceiro from "../../pages/parceiro/PageEditarParceiro";
import PageEditarVagaTrabalho from "../../pages/parceiro/PageEditarVagaTrabalho";
import PageExcluirParceiro from "../../pages/parceiro/PageExcluirParceiro";
import PageHomeParceiro from "../../pages/parceiro/PageHomeParceiro";
import PageInformacoesComplementares from "../../pages/parceiro/PageInformacoesComplementares";
import PageInformarSituacaoContato from "../../pages/parceiro/PageInformarSituacaoContato";
import PageInteressesPessoaMobilizacao from "../../pages/parceiro/PageInteressesPessoaMobilizacao";
import PageJaSouEmpreendedor from "../../pages/parceiro/PageJaSouEmpreendedor";
import PageListarCursos from "../../pages/parceiro/PageListarCursos";
import PageListarInteressadosMPO from "../../pages/parceiro/PageListarInteressadosMPO";
import PageListarInteressadosVagaTrabalho from "../../pages/parceiro/PageListarInteressadosVagasTrabalho";
import PageListarParceiros from "../../pages/parceiro/PageListarParceiros";
import PageListarVagasRegiaoCidadao from "../../pages/parceiro/PageListarVagasRegiaoCidadao";
import PageListarVagaTrabalho from "../../pages/parceiro/PageListarVagaTrabalho";
import PageMicrocreditoFAQ from "../../pages/parceiro/PageMicrocreditoFAQ";
import PageMicrocreditoMPO from "../../pages/parceiro/PageMicrocreditoMPO";
import PageMicrocreditoMPOQuestionario from "../../pages/parceiro/PageMicrocreditoMPOQuestionario";
import PageMobilizacaoBuscarCursos from "../../pages/parceiro/PageMobilizacaoBuscarCursos";
import PageMobilizacaoBuscarVagas from "../../pages/parceiro/PageMobilizacaoBuscarVagas";
import PageMobilizacaoCidadao from "../../pages/parceiro/PageMobilizacaoCidadao";
import PageMobilizacaoListarCursosRegiaoCidadao from "../../pages/parceiro/PageMobilizacaoListarCursosRegiaoCidadao";
import PageMobilizacaoVisualizarInformacoesCurso from "../../pages/parceiro/PageMobilizacaoVisualizarInformacoesCurso";
import PageNovaInscricaoParceiro from "../../pages/parceiro/PageNovaInscricaoParceiro";
import PageNovaVagaTrabalho from "../../pages/parceiro/PageNovaVagaTrabalho";
import PageNovoCurso from "../../pages/parceiro/PageNovoCurso";
import PageNovoCursoEmpreender from "../../pages/parceiro/PageNovoCursoEmpreender";
import PageQueroSerEmpreendedor from "../../pages/parceiro/PageQueroSerEmpreendedor";
import PageRelacaoInteressados from "../../pages/parceiro/PageRelacaoInteressados";
import PageUsuariosParceiro from "../../pages/parceiro/PageUsuariosParceiro";
import PageVisualizacaoParceiro from "../../pages/parceiro/PageVisualizacaoParceiro";
import PageVisualizarCurriculo from "../../pages/parceiro/PageVisualizarCurriculo";
import PageVisualizarCurriculoMobilizacao from "../../pages/parceiro/PageVisualizarCurriculoMobilizacao";
import PageVisualizarCurso from "../../pages/parceiro/PageVisualizarCurso";
import PageVisualizarInformacoesComplementares from "../../pages/parceiro/PageVisualizarInformacoesComplementares";
import PageVisualizarInformacoesVaga from "../../pages/parceiro/PageVisualizarInformacoesVaga";
import Profile from "../../pages/Profile";

const queryClient = new QueryClient();

const ParceiroRoutes = () => (
  <QueryClientProvider client={queryClient}>
    <Routes>
      <Route
        path="/"
        element={<PageHomeParceiro />}
      />
      <Route
        path="/home"
        element={<PageHomeParceiro />}
      />
      <Route
        path="/profile"
        element={<Profile />}
      />

      {/* Cadastro empresa */}
      <Route
        path="/parceiro/cadastro"
        element={<PageNovaInscricaoParceiro />}
      />
      <Route
        path="/parceiro/visualizar"
        element={<PageVisualizacaoParceiro />}
      />
      <Route
        path="/parceiro/listar-parceiros"
        element={<PageListarParceiros />}
      />
      <Route
        path="/parceiro/representantes/:id?"
        element={<PageUsuariosParceiro />}
      />
      <Route
        path="/parceiro/editar/:id?"
        element={<PageEditarParceiro />}
      />
      <Route
        path="/parceiro/excluir/:id?"
        element={<PageExcluirParceiro />}
      />
      <Route
        path="/parceiro/empresa-complementar/cadastrar/:id?"
        element={<PageInformacoesComplementares />}
      />
      <Route
        path="/parceiro/empresa-complementar/visualizar/:id"
        element={<PageVisualizarInformacoesComplementares />}
      />

      {/* Cursos */}
      <Route
        path="/parceiro/cursos/cadastrar"
        element={<PageNovoCurso />}
      />
      <Route
        path="/parceiro/cursos/editar"
        element={<PageEditarCurso />}
      />
      <Route
        path="/parceiro/cursos/duplicar"
        element={<PageDuplicarCurso />}
      />
      <Route
        path="/parceiro/cursos/listar"
        element={<PageListarCursos />}
      />
      <Route
        path="/parceiro/cursos/visualizar/:id?"
        element={<PageVisualizarCurso />}
      />
      <Route
        path="/parceiro/cursos/interessados"
        element={<PageRelacaoInteressados />}
      />

      {/* Vagas de trabalho */}
      <Route
        path="/parceiro/vagas/cadastrar"
        element={<PageNovaVagaTrabalho />}
      />
      <Route
        path="/parceiro/vagas/editar"
        element={<PageEditarVagaTrabalho />}
      />
      <Route
        path="/parceiro/vagas/duplicar"
        element={<PageDuplicarVagaTrabalho />}
      />
      <Route
        path="/parceiro/vagas/listar"
        element={<PageListarVagaTrabalho />}
      />
      <Route
        path="/parceiro/vagas/interessados"
        element={<PageListarInteressadosVagaTrabalho />}
      />
      <Route
        path="/parceiro/vagas/curriculos/busca"
        element={<PageBuscarCurriculoRede />}
      />
      <Route
        path="/parceiro/vagas/curriculos/visualizar"
        element={<PageVisualizarCurriculo />}
      />

      {/* Mobilização */}
      <Route
        path="/parceiro/mobilizacao/curriculos/buscar"
        element={<PageBuscarCurriculoMobilizacao />}
      />
      <Route
        path="/parceiro/mobilizacao/curriculos/visualizar"
        element={<PageVisualizarCurriculoMobilizacao />}
      />
      <Route
        path="/parceiro/mobilizacao/interesses-da-pessoa"
        element={<PageInteressesPessoaMobilizacao />}
      />
      <Route
        path="/parceiro/mobilizacao/mobilizacao-individual-cidadao"
        element={<PageMobilizacaoCidadao />}
      />
      <Route
        path="/parceiro/mobilizacao/curriculo-cidadao"
        element={<PageCurriculoCidadao />}
      />
      <Route
        path="/parceiro/mobilizacao/editar-curriculo-cidadao"
        element={<PageEditarCurriculoCidadao />}
      />
      <Route
        path="/parceiro/mobilizacao/vagas/listar"
        element={<PageListarVagasRegiaoCidadao />}
      />
      <Route
        path="/parceiro/mobilizacao/vagas/visualizar"
        element={<PageVisualizarInformacoesVaga />}
      />
      <Route
        path="/parceiro/mobilizacao/cursos/buscar"
        element={<PageMobilizacaoBuscarCursos />}
      />
      <Route
        path="/parceiro/mobilizacao/vagas/buscar"
        element={<PageMobilizacaoBuscarVagas />}
      />
      <Route
        path="/parceiro/mobilizacao/cursos/listar"
        element={<PageMobilizacaoListarCursosRegiaoCidadao />}
      />
      <Route
        path="/parceiro/mobilizacao/cursos/visualizar"
        element={<PageMobilizacaoVisualizarInformacoesCurso />}
      />
      <Route
        path="/parceiro/mobilizacao/listar-mpo/consultar"
        element={<PageConsultarMPO />}
      />
      <Route
        path="/parceiro/mobilizacao/cursos/novo"
        element={<PageNovoCursoEmpreender />}
      />

      {/* Microcrédito */}
      <Route
        path="/parceiro/microcredito/quero-ser-empreendedor"
        element={<PageQueroSerEmpreendedor />}
      />
      <Route
        path="/parceiro/microcredito/ja-sou-empreendedor"
        element={<PageJaSouEmpreendedor />}
      />
      <Route
        path="/parceiro/microcredito/acesso-mpo"
        element={<PageAcessoMicrocreditoMPO />}
      />
      <Route
        path="/parceiro/microcredito/acesso-mpo-aprovado"
        element={<PageAcessoMicrocreditoMPOAprovado />}
      />
      <Route
        path="/parceiro/microcredito/credito-mpo"
        element={<PageMicrocreditoMPO />}
      />
      <Route
        path="/parceiro/microcredito/credito-mpo/questionario"
        element={<PageMicrocreditoMPOQuestionario />}
      />
      <Route
        path="/parceiro/microcredito/informar-situacao-contato"
        element={<PageInformarSituacaoContato />}
      />
      <Route
        path="/parceiro/microcredito/FAQ"
        element={<PageMicrocreditoFAQ />}
      />
      <Route
        path="/parceiro/listar-mpo"
        element={<PageListarInteressadosMPO />}
      />

      {/* Components */}
      <Route
        path="/components"
        element={<PageComponents />}
      />
      <Route
        path="/components-default-table"
        element={<PageComponentsDefaultTable />}
      />
      <Route
        path="/components-form"
        element={<PageComponentsForm />}
      />
      <Route
        path="/faq"
        element={<FAQ />}
      />
      <Route
        path="*"
        element={<NotFound />}
      />

      {/* Auth routes should be home */}
      <Route
        path="/parceiro_login"
        element={<PageHomeParceiro />}
      />
      <Route
        path="/admin_login"
        element={<PageHomeParceiro />}
      />
      <Route
        path="/cidadao_login"
        element={<PageHomeParceiro />}
      />
      <Route
        path="/login"
        element={<PageHomeParceiro />}
      />
      <Route
        path="/register"
        element={<PageHomeParceiro />}
      />
    </Routes>
  </QueryClientProvider>
);

export default ParceiroRoutes;
