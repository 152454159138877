import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Container } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminAnalisarEmpresaComplementarParceiro from "../../components/formularios/AdminAnalisarEmpresaComplementarParceiro";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import adminService from "../../services/admin.service";
import constantsService from "../../services/constants.service";

const AnalisarInformacoesComplementares = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [valores, setValores] = useState(null);
  // const [linkRetorno, setLinkRetorno] = useState("/parceiro/listar/pendentes");

  const {
    isPending,
    isError,
    data: initialData,
    error,
  } = useQuery({
    queryKey: ["empresaParceiraEComplementar", id],
    queryFn: ({ queryKey }) => {
      const [, id] = queryKey;
      return adminService.getEmpresaParceiraEComplementar(id);
    },
    enabled: !!id,
  });

  useEffect(() => {
    if (initialData) {
      let statusEmpresaParceira = initialData.empresaComplementarDados.situacao.toLowerCase().trim();
      // console.log('o status da situação da empresa parceira é: ', statusEmpresaParceira)

      if (statusEmpresaParceira !== "aprovado" && statusEmpresaParceira !== "reprovado") {
        statusEmpresaParceira = "";
      }

      setValores({
        ...initialData,
        status: statusEmpresaParceira,
        motivo: "",
      });
    }
  }, [initialData]);

  const handleSubmit = async values => {
    // console.log("values: ", values);
    let files = values.anexo;

    try {
      if (files && files.length > 0) {
        // Map through the files to create an array of `arquivo` objects
        const arquivos = files.map(file => ({
          nome_anexo: file.name.split(".")[0],
          extensao_anexo: file.name.split(".")[1],
          file: file.file, // Passar o file
        }));

        // Use postInsereArquivos to upload all files
        adminService
          .postInsereArquivos(arquivos)
          .then(respostas => {
            const ids = respostas.map(resposta => resposta.id);
            console.log("Uploaded file IDs:", ids);

            const empresaPayload = {
              // Aqui é o id da empresa complementar, não do parceiro!!!
              empresa_id: values.empresaComplementar.toString(),
              status: constantsService.getCodeByStatus(values.status),
              mensagem: values.motivo,
              anexo: ids,
            };

            // if (values.status == "Aprovado") {
            //   setLinkRetorno("/parceiro/listar/aprovados");
            // } else if (values.status == "Reprovado") {
            //   setLinkRetorno("/parceiro/listar/reprovados");
            // }

            adminService
              .postAtualizarStatusEmpresaComplementar(empresaPayload)
              .then(mensagem => {
                console.log("Sucesso:", mensagem);
                alert("Operação realizada com sucesso!");

                navigate("/parceiro/listar/pendentes");
                // navigate(linkRetorno);
              })
              .catch(erro => {
                console.error("Erro ao atualizar status da empresa complementar", erro);
                alert("Erro inesperado, favor tentar novamente mais tarde");
              });
          })
          .catch(error => {
            console.error("Erro ao inserir arquivo", error);
          });
      } else {
        alert("Insira um arquivo");
        return;
      }
    } catch (erro) {
      console.error("Erro ao avaliar empresa complementar", erro);
    }
  };

  if (isError) {
    console.log("Erro de carregamento:", error);
  }

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/parceiro/listar/pendentes", text: "Listar Parceiros Pendentes" }]}
        currentPage="Analisar Informações Complementares"
      />

      <TitlePage title={"Analisar Informações Complementares"} />

      {isPending || !valores ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
          widescreen={true}
        />
      ) : (
        <AdminAnalisarEmpresaComplementarParceiro
          valores={valores}
          id={id}
          onSubmit={handleSubmit}
        />
      )}
    </Container>
  );
};

export default AnalisarInformacoesComplementares;
