import React from "react";
import { useParams } from "react-router-dom";

import { Card, Container, Grid, Typography } from "@mui/material";
import { useQueries } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import LoadingComponent from "../../components/loading/LoadingComponent";
import DefaultTable from "../../components/table/DefaultTable";
import TitlePage from "../../components/title-page/TitlePage";
import adminService from "../../services/admin.service";

const PageVisualizarAlteracoes = () => {
  const { id } = useParams();

  const results = useQueries({
    queries: [
      {
        queryKey: ["dadosAlterados"],
        queryFn: () => adminService.getLogsEmpresa(id),
      },
      { queryKey: ["empresaParceira"], queryFn: () => adminService.getEmpresaParceira(id) },
    ],
  });

  // Desestruturação dos resultados das queries
  const [dadosAlteradosQuery, empresaQuery] = results;

  // Dados carregados com sucesso
  const dadosAlterados = dadosAlteradosQuery.data;
  const empresa = empresaQuery.data;

  // Variáveis de estado
  const isError = dadosAlteradosQuery.isError || empresaQuery.isError;
  const isPending = dadosAlteradosQuery.isLoading || empresaQuery.isLoading;

  const colunas = [
    { field: "modificacao", headerName: "Modificação" },
    { field: "campoAlterado", headerName: "Campo alterado" },
    { field: "informacaoAnterior", headerName: "Informação anterior" },
    { field: "novaInformacao", headerName: "Nova informação" },
  ];

  const nome = empresa?.razaoSocial ? empresa?.razaoSocial : "...";

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/parceiro/listar/pendentes", text: "Listar Parceiros Pendentes" },
          { href: "/parceiro/analisar/" + id, text: "Analisar" },
        ]}
        currentPage="Informações alteradas"
      />

      <TitlePage title={"Informações alteradas"} />

      <Card
        color="#ffffff"
        sx={{
          borderRadius: "8px",
          padding: "16px",
          mt: "24px",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{ mb: "16px" }}
        >
          <Typography variant="subtitle1">Informações alteradas no cadastro do parceiro {nome}</Typography>
        </Grid>

        {isPending ? (
          <LoadingComponent
            isLoading={true}
            fullScreen={false}
            widescreen={true}
          />
        ) : (
          <DefaultTable
            columns={colunas}
            rows={dadosAlterados}
          />
        )}
      </Card>

      {isError && <ErroCarregamentoModal isShow={true} />}
    </Container>
  );
};

export default PageVisualizarAlteracoes;
