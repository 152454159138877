import React, { useRef } from "react";
import { useForm } from "react-hook-form";

import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { Card, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

import CustomDatePicker from "../formularios/CustomDatePicker";
import { FormFieldsGrid } from "./DynamicForm.js";
import ReusableSwitch from "./fields/ReusableSwitch.js";

const valoresIniciais = {
  contatadoPorOperador: false,
  dataContato: null,
  parceiroContato: "",
  emprestimoConcedido: false,
  quantidadeParceirosEmprestimo: "",
  dataEmprestimo: null,
  valorEmprestimo: "",
  parceiroCredito: "",
  iniciouNegocio: false,
  dataInicioNegocio: null,
  situacaoNegocioRadio: "",
  cnpj: "",
};

const FormInformarSituacaoContato = ({ initialValues = valoresIniciais, readOnly = false }) => {
  const theme = useTheme();

  const formRef = useRef();

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    mode: "onBlur", // or "onSubmit", "onChange", depending on when you want validation to trigger
  });

  // const handleSubmitAntigo = values => {
  //   console.log("SEND OBJ -> ", values);
  // };

  const handleKeyDown = event => {
    // Impedir que o formularário seja enviado ao pressionar a tecla Enter
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const fieldsDados1 = [
    {
      name: "contatadoPorOperador",
      label: "contatadoPorOperador",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">1. Você já foi contatado por algum operador de microcrédito parceiro do Acredita?</Typography>
          <ReusableSwitch
            id={"contatadoPorOperador"}
            name={"contatadoPorOperador"}
            value={value ?? false}
            onChange={onChange}
            disabled={false}
          />
        </>
      ),
      size: { xs: 12, sm: 12 },
    },
    {
      name: "dataContato",
      label: "Mês e ano do contato",
      renderComponent: ({ value, onChange }) => (
        <CustomDatePicker
          label="Mês e ano do contato"
          id="dataContato"
          name="dataContato"
          value={value}
          format="DD/MM/YYYY"
          onChange={onChange}
        />
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "parceiroContato",
      label: "Qual parceiro que fez o contato",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <TextField
            label="Qual parceiro que fez o contato"
            id="parceiroContato"
            name="parceiroContato"
            value={value}
            placeholder="Insira o nome do parceiro que fez o contato"
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6 },
    },
  ];

  const fieldsDados2 = [
    {
      name: "emprestimoConcedido",
      label: "emprestimoConcedido",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">
            2. Você já teve o empréstimo concedido por algum operador de microcrédito parceiro do Acredita?
          </Typography>
          <ReusableSwitch
            id={"emprestimoConcedido"}
            name={"emprestimoConcedido"}
            value={value ?? false}
            onChange={onChange}
            disabled={false}
          />
        </>
      ),
      size: { xs: 12, sm: 12 },
    },
    {
      name: "quantidadeParceirosEmprestimo",
      label: "De quantos parceiros obteve empréstimo?",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <TextField
            label="De quantos parceiros obteve empréstimo?"
            id="quantidadeParceirosEmprestimo"
            name="quantidadeParceirosEmprestimo"
            value={value}
            placeholder="Insira a quantidade de parceiros que obtever empréstimo"
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "dataEmprestimo",
      label: "Mês e ano do empréstimo",
      renderComponent: ({ value, onChange }) => (
        <CustomDatePicker
          label="Mês e ano do empréstimo"
          id="dataEmprestimo"
          name="dataEmprestimo"
          value={value}
          format="DD/MM/YYYY"
          onChange={onChange}
        />
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "valorEmprestimo",
      label: "Valor do empréstimo",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <TextField
            label="Valor do empréstimo"
            id="valorEmprestimo"
            name="valorEmprestimo"
            value={value}
            placeholder="Insira o valor do empréstimo"
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "parceiroCredito",
      label: "Qual parceiro concedeu o empréstimo?",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <TextField
            label="Qual parceiro concedeu o empréstimo?"
            id="parceiroCredito"
            name="parceiroCredito"
            value={value}
            placeholder="Insira o nome do parceiro concedeu o empréstimo?"
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6 },
    },
  ];

  const fieldsDados3 = [
    {
      name: "iniciouNegocio",
      label: "iniciouNegocio",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">3. Você já iniciou o seu negócio?</Typography>
          <ReusableSwitch
            id={"iniciouNegocio"}
            name={"iniciouNegocio"}
            value={value ?? false}
            onChange={onChange}
            disabled={false}
          />
        </>
      ),
      size: { xs: 12, sm: 12 },
    },
    {
      name: "dataInicioNegocio",
      label: "Mês e ano do início",
      renderComponent: ({ value, onChange }) => (
        <CustomDatePicker
          label="Mês e ano do início"
          id="dataInicioNegocio"
          name="dataInicioNegocio"
          value={value}
          format="DD/MM/YYYY"
          onChange={onChange}
        />
      ),
      size: { xs: 12, sm: 12 },
    },
    {
      name: "situacaoNegocioRadio",
      label: "Qual a situação do negócio?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">Qual a situação do negócio?</Typography>
          <FormGroup>
            <RadioGroup
              name="situacaoNegocioRadio"
              onChange={onChange}
              value={value || ""}
              // disabled={visualizacao}
            >
              <FormControlLabel
                value="formal"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "formal"}
                    // disabled={visualizacao}
                  />
                }
                label="Formal (MEI, CNPJ)"
              />
              <FormControlLabel
                value="informal"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "informal"}
                    // disabled={visualizacao}
                  />
                }
                label="Informal (sem registro)"
              />
            </RadioGroup>
          </FormGroup>
        </>
      ),
      size: { sx: 12 },
    },
    {
      name: "cnpj",
      label: "CNPJ",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <TextField
            label="CNPJ"
            id="cnpj"
            name="cnpj"
            value={value}
            placeholder="Insira o seu CNPJ"
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 9 },
    },
  ];

  return (
    <form
      ref={formRef}
      // TODO: coloquei isso aqui mas depois vi que não é correto, devemos tirar depois
      method="post"
      onSubmit={event => {
        event.preventDefault(); // Previne o comportamento padrão do formulário
        // handleSubmitAntigo(values); // Só para mostrar os dados antigos

        console.log("Valores reais do formulario:", getValues());

        // Chama o handleSubmit do React Hook Form, que valida e envia os dados
        handleSubmit(data => {
          console.log("Valores validados e reais do formulário:", data);
        })();

        // Se você quer ver os erros de validação
        console.log("Erros de validação:", errors);
      }}
      onKeyDown={handleKeyDown}
    >
      <Stack spacing={1}>
        {/* Card Passo 1 */}
        <Card
          color="#ffffff"
          sx={{ borderRadius: "8px", padding: "16px" }}
          style={{ transition: "1s" }}
        >
          <FormFieldsGrid
            fields={fieldsDados1}
            control={control}
            errors={errors}
          />
        </Card>
        {/* Card Passo 2 */}
        <Card
          color="#ffffff"
          sx={{ borderRadius: "8px", padding: "16px" }}
          style={{ transition: "1s" }}
        >
          <FormFieldsGrid
            fields={fieldsDados2}
            control={control}
            errors={errors}
          />
        </Card>

        {/* Card Passo 3 */}
        <Card
          color="#ffffff"
          sx={{ borderRadius: "8px", padding: "16px" }}
          style={{ transition: "1s" }}
        >
          <FormFieldsGrid
            fields={fieldsDados3}
            control={control}
            errors={errors}
          />
        </Card>

        <Grid
          container
          spacing={1}
          justifyContent="flex-end"
        >
          <Grid
            item
            xs={12}
            sm={"auto"}
          >
            <LoadingButton
              size="large"
              type="submit"
              variant="outlined"
              onClick={async () => {
                "";
              }}
              // loading={loading || isSubmitting}
              fullWidth
            >
              CANCELAR
            </LoadingButton>
          </Grid>
          <Grid
            item
            xs={12}
            sm={"auto"}
          >
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              onClick={async () => {
                "";
              }}
              // loading={loading || isSubmitting}
              fullWidth
            >
              <SaveIcon sx={{ mb: "4px", mr: "4px", width: "20px", height: "20px" }} />
              Salvar
            </LoadingButton>
          </Grid>
        </Grid>
      </Stack>
    </form>
  );
};

FormInformarSituacaoContato.propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  confirmacaoModal: PropTypes.bool,
  setConfirmacaoModal: PropTypes.func,
  setErroModal: PropTypes.func,
  erroModal: PropTypes.bool,
  erroDoUsuarioModal: PropTypes.bool,
  initialValues: PropTypes.object,
  readOnly: PropTypes.bool,
};

export default FormInformarSituacaoContato;
