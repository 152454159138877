import React from "react";

import { Container } from "@mui/material";

import WarningBox from "../../components/boxes/WarningBox";
import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ParceiroInteressesPessoaMobilizacao from "../../components/formularios/ParceiroInteressesPessoaMobilizacao";
import TitlePage from "../../components/title-page/TitlePage";

const PageInteressesPessoaMobilizacao = () => {
  const value = {
    nome: "Teste",
    pcd: "Não",
    escolaridade: "Teste",
    municipio_uf: "Teste",
    habilidade: "Teste",
    experiencia: "Teste",
    cursos_interesse: "Teste",
    vagas_interesse: "Teste",
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/parceiro", text: "Parceiro" },
          { href: "/parceiro/mobilizacao", text: "Mobilização" },
          { href: "/parceiro/mobilizacao/curriculos/buscar", text: "Buscar Currículo" },
        ]}
        currentPage={"Interesses Pessoa Mobilização"}
      />

      <WarningBox />

      <TitlePage title={"Interesses da Pessoa"} />

      <ParceiroInteressesPessoaMobilizacao
        value={value}
        visualizacao={true}
      />
    </Container>
  );
};

export default PageInteressesPessoaMobilizacao;
