export const transformaParaBackend = values => {
  const newValues = {
    id_empresa_parceira: values.cnpjOfertante, //recebo o id da empresa
    tp_vaga: values.tipoVaga,
    nome_funcao: values.funcao,
    qt_vaga: values.quantidadeVagas,
    st_escolaridade_minima: values.escolaridadeMinima ? "SIM" : "NAO",
    vl_salario_bruto: Number(values.salario),
    ds_adicional: values.descricao,
    st_pessoas_deficiencia: values.vagaPCD ? "SIM" : "NAO",
    st_local_trabalho_parceiro: values.localTrabalhoParceiro ? "SIM" : "NAO",
    tp_escolaridade: values.escolaridadeRequerida,
    tp_turno: values.turno,
    validade: Number(values.validadeAnuncio),
    endereco: {
      cep: values.endereco[0].cep.replace(/[.-]/g, ""),
      logradouro: values.endereco[0].endereco,
      numero: values.endereco[0].numero || "0",
      complemento: values.endereco[0].complemento || "",
      bairro: values.endereco[0].bairro,
      cidade: values.endereco[0].municipio,
      uf: values.endereco[0].uf,
      // referencia: values.endereco[0].referencia || "",
      referencia: "teste", //TODO: arrumar no FormAddress
    },
    beneficios: [
      values.auxilioAlimentacao && {
        tipo: "AA",
        descricao: values.detalheAuxilioAlimentacao,
      },
      values.auxilioTransporte && {
        tipo: "AT",
        descricao: values.detalheAuxilioTransporte,
      },
      values.planoSaude && {
        tipo: "PS",
        descricao: values.detalhePlanoSaude,
      },
      values.outros && {
        tipo: "OT",
        descricao: values.detalheOutros,
      },
    ].filter(Boolean),
    // se não tiver nenhum, tem que ser enviado vazio: "areas_atuacao": []
    areas_atuacao: [
      // Profissional
      ...(values.areaAtuacaoProfissional?.map(area => ({
        tipo_atuacao: area.input3,
        tipo_vaga: "P", // Profissional
        cbo2002_ocupacao: Number(area.input1),
        cbo2002_ocupacao_anterior: Number(area.input2),
      })) || []),

      // Estudante
      ...(values.areaAtuacaoEstudante?.map(area => ({
        tipo_atuacao: area.input3,
        tipo_vaga: "E", // Estudante
        cbo2002_ocupacao: Number(area.input1),
        cbo2002_ocupacao_anterior: Number(area.input2),
      })) || []),
    ],
    // Essa parte ainda está em discussão com a equipe de informação!!!
    // se não tiver nenhum, tem que ser enviado vazio: "cursos": []
    cursos: [
      ...(values.cursoComplementar?.map(curso => ({
        id_curso: curso.input1, // ID do curso
        tipo_atuacao: curso.input3,
        tipo_vaga: "FIC", // Formação Inicial Continuada
      })) || []),

      ...(values.cursoTecnico?.map(curso => ({
        id_curso: curso.input1,
        tipo_atuacao: curso.input3,
        tipo_vaga: "CUT", // Curso Técnico
      })) || []),

      ...(values.cursoSuperior?.map(curso => ({
        id_curso: curso.input1,
        tipo_atuacao: curso.input3,
        tipo_vaga: "SUP", // Curso Superior
      })) || []),
    ],
  };

  return newValues;
};
