import dayjs from "dayjs";

import transformDadosEmpresaComplementar from "../utils/parceiroEmpresaComplementarUtils";
import { transformarDadosUnicaEmpresa, transformEmpresasComDetalhes } from "../utils/parceiroEmpresaUtils";
import axiosInstance from "./axiosConfig";
import API_URL from "./config";
import constantsService from "./constants.service";

/**
 * Recupera um parceiro usando o token de acesso fornecido e o ID fornecido.
 *
 * @param {number} id - O ID do parceiro a ser recuperado
 * @return {Promise} Uma Promessa que resolve para os dados do parceiro recuperado
 */
const getParceiroData = () => {
  return axiosInstance
    .get(API_URL + "v1/parceiros/index/", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      // console.error("Error fetching parceiro data:", error);
      throw error;
    });
};

/**
 * Cadastra uma nova empresa parceira.
 *
 * @param {Object} data - Os dados da empresa parceira a serem cadastrados.
 * @return {Promise<string>} Uma promessa que resolve para a mensagem de sucesso da operação.
 * @throws {Error} Se ocorrer algum erro durante o cadastro da empresa parceira.
 */
const postCadastrarEmpresa = data => {
  return axiosInstance
    .post(API_URL + `v1/parceiros/empresa/cadastro`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      // console.log("Service parceiro");
      return response.data.empresa_id;
    })
    .catch(error => {
      console.error("Error cadastrando empresa parceira:", error);
      throw error;
    });
};

/**
 * Recupera uma lista de empresas parceiras.
 *
 * @return {Promise<Array>} Uma promessa que resolve para um array de empresas parceiras.
 */
const getEmpresasParceiras = () => {
  return axiosInstance
    .get(API_URL + `v1/parceiros/empresas`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error("Error fetching empresas parceiras:", error);
      throw error;
    });
};

/**
 * Recupera os detalhes de uma empresa parceira usando o ID fornecido.
 *
 * @param {number} id - O ID da empresa parceira a ser recuperada.
 * @return {Promise<Object>} Uma Promessa que resolve para os detalhes da empresa parceira recuperada.
 * @throws {Error} Se ocorrer algum erro durante a recuperação dos detalhes da empresa parceira.
 */
const getEmpresaData = id => {
  return axiosInstance
    .get(API_URL + `v1/parceiros/empresa/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      return transformarDadosUnicaEmpresa(response.data);
    })
    .catch(error => {
      console.error("Error fetching empresa parceira details:", error);
      throw error;
    });
};

/**
 * Recupera uma lista de empresas parceiras com seus detalhes.
 *
 * @return {Promise<Array>} Uma promessa que resolve para um array de empresas parceiras com seus detalhes.
 */
const getEmpresasParceirasComDetalhes = async () => {
  try {
    const response = await getEmpresasParceiras();

    // Mapear cada empresa para buscar detalhes individuais
    const empresas = Object.values(response);

    // Map each empresa to fetch individual details
    const detalhesPromises = empresas.map(empresa => {
      return getEmpresaData(empresa.pk_empresa_parceira);
    });

    // Esperar por todas as chamadas de detalhes
    const detalhes = await Promise.all(detalhesPromises);

    // Combinar dados brutos com detalhes
    const empresasComDetalhes = empresas.map((empresa, index) => ({
      ...empresa,
      detalhes: detalhes[index],
    }));

    return transformEmpresasComDetalhes(empresasComDetalhes);
  } catch (error) {
    console.error("Error fetching empresas parceiras:", error);
    throw error;
  }
};

/**
 * Exclui uma empresa parceira usando o ID fornecido.
 *
 * @param {number} id - O ID da empresa parceira a ser excluída.
 * @param {string} motivo - O motivo da exclusão da empresa parceira.
 * @return {Promise<string>} Uma Promessa que resolve para a mensagem de sucesso da operação.
 * @throws {Error} Se ocorrer algum erro durante a exclusão da empresa parceira.
 */
const excluiEmpresaParceira = (id, motivo) => {
  return axiosInstance
    .delete(API_URL + `v1/parceiros/empresa/exclui/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: {
        motivo: motivo,
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error("Error deleting empresa parceira:", error);
      throw error;
    });
};

/**
 * Atualiza os dados de uma empresa parceira usando o ID fornecido.
 *
 * @param {number} id - O ID da empresa parceira a ser atualizada.
 * @param {Object} empresaData - Os novos dados da empresa parceira.
 * @return {Promise<string>} Uma Promessa que resolve para a mensagem de sucesso da operação.
 * @throws {Error} Se ocorrer algum erro durante a atualização dos dados da empresa parceira.
 */
const atualizaEmpresaParceira = (id, empresaData) => {
  return axiosInstance
    .put(API_URL + `v1/parceiros/empresa/atualiza/${id}`, empresaData, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    })
    .then(response => {
      console.log("Empresa atualizada com sucesso");
      return response.data;
    })
    .catch(error => {
      console.error("Error updating empresa parceira:", error);
      throw error;
    });
};

const postCadastrarEmpresaComplementar = data => {
  return axiosInstance
    .post(API_URL + `v1/parceiros/empresa_complementar/cadastro`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      console.log("Empresa complementar cadastrada com sucesso");
      return response.data;
    })
    .catch(error => {
      console.error("Error cadastrando empresa complementar parceira:", error);
      throw error;
    });
};

const getEmpresaComplementar = id => {
  return axiosInstance
    .get(API_URL + `v1/parceiros/empresa_complementar/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      const dados = transformDadosEmpresaComplementar(response.data);
      return dados;
    })
    .catch(error => {
      console.error("Error fetching empresa complementar parceira details:", error);
      throw error;
    });
};

const atualizaEmpresaComplementar = (id, data) => {
  return axiosInstance
    .put(API_URL + `v1/parceiros/empresa_complementar/atualiza/${id}`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    })
    .then(response => {
      console.log("Empresa complementar atualizada com sucesso");
      return response.data;
    })
    .catch(error => {
      console.error("Erro ao tentar atualizar empresa complementar", error);
      throw error;
    });
};

/**
 * Cadastra um anexo.
 *
 * @param {Array} arquivos - Os arquivos a serem enviados no corpo da requisição.
 * @return {Promise<string>} Uma promessa que resolve para a mensagem de sucesso da operação.
 * @throws {Error} Se ocorrer algum erro durante a inserção dos anexos.
 */
const postInsereArquivo = file => {
  try {
    return axiosInstance
      .post(API_URL + `v1/parceiros/anexo/insere_foto_curso/`, file, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(response => {
        // console.log("Inseri arquivo", response.data);
        // Preciso pegar o response.data.id para colocar no id do curso
        return response.data;
      })
      .catch(error => {
        console.error("Error Post Insere Arquivo:", error);
        throw error;
      });
  } catch (error) {
    const erro = "Erro Post Insere Arquivo:" + error;
    throw erro;
  }
};

/**
 * Cadastra uma novo curso.
 *
 * @param {Object} data - Os dados do curso a serem cadastrados.
 * @return {Promise<string>} Uma promessa que resolve para a mensagem de sucesso da operação.
 * @throws {Error} Se ocorrer algum erro durante o cadastro do curso.
 */
const postCadastrarCurso = data => {
  return axiosInstance
    .post(API_URL + `v1/parceiros/curso/cadastro`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      console.log("Service parceiro", response.data);
      return response.data.message;
    })
    .catch(error => {
      console.error("Error cadastrando curso:", error);
      throw error;
    });
};

/**
 * Obter a lista de cursos.
 *
 * @return {Promise<string>} Uma promessa que resolve para os dados dos cursos cadastrados.
 * @throws {Error} Se ocorrer algum erro durante a obtenção dos cursos.
 */
const getCursos = () => {
  return axiosInstance
    .get(API_URL + `v1/parceiros/cursos`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(async response => {
      console.log("Service parceiro", response.data);
      return response.data;

      //TODO: esperar ajuste do backend. Acho que a informaçao de data vem do curso e nao da empresa
      // const info = await Promise.all(
      //   response.data.map(async dados => {
      //     const dadosEmpresa = await getEmpresaData(dados.dados.fk_empresa_parceira);
      //     let recente = dayjs();
      //     dados.detalhes.map(dado => {
      //       if (recente > dayjs(dado.dh_atualizacao)) {
      //         recente = dayjs(dado.dh_atualizacao);
      //       }
      //     });
      //     return {
      //       ...dados.dados,
      //       ...dados.detalhes,
      //       ...dadosEmpresa,
      //       dataUltimaModificacao: recente,
      //       id: dados.curso_id,
      //       status: constantsService.getStatusByCode(dados.tp_situacao_registro),
      //     };
      //   }),
      // );
      // return info;
    })
    .catch(error => {
      console.error("Erro obtendo cursos:", error);
      throw error;
    });
};

/**
 * Obter os dados de um curso.
 *
 * @param {Int} od - O id do curso a ser retornado.
 * @return {Promise<string>} Uma promessa que resolve para os dados do curso
 * @throws {Error} Se ocorrer algum erro durante a obtenção do curso.
 */
const getCurso = id => {
  return axiosInstance
    .get(API_URL + `v1/parceiros/curso/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(async response => {
      console.log("Service parceiro", response.data);
      const dadosEmpresa = await getEmpresaData(response.data.dados.fk_empresa_parceira);
      let recente = dayjs();
      response.data.detalhes.map(dado => {
        if (recente > dayjs(dado.dh_atualizacao)) {
          recente = dayjs(dado.dh_atualizacao);
        }
      });
      return {
        ...response.data.dados,
        ...dadosEmpresa,
        dataUltimaModificacao: recente,
        detalhes: response.data.detalhes,
        id: response.data.curso_id,
        status: constantsService.getStatusByCode(response.data.tp_situacao_registro),
      };
    })
    .catch(error => {
      console.error("Erro obtendo curso:", error);
      throw error;
    });
};

const getTrilhasCursos = () => {
  return axiosInstance
    .get(API_URL + `v1/parceiros/trilha_curso`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error("Error obtendo trilhas:", error);
      throw error;
    });
};

/**
 * Cadastra um novo representante de uma empresa  usando dados fornecidos.
 *
 * @param {Object} data - Os dados do representante a ser cadastrado.
 * @return {Promise<string>} Uma promessa que resolve para a mensagem de sucesso da operação.
 * @throws {Error} Se ocorrer algum erro durante o cadastro do representante.
 */
const postRepresentanteEmpresa = data => {
  return axiosInstance
    .post(API_URL + `v1/parceiros/representantes/cadastro`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    })
    .then(response => {
      console.log(response.data.message);
    })
    .catch(error => {
      console.error("Erro ao cadastrar representante: ", error);
      throw error;
    });
};

/**
 * Obtem a lista de representantes de uma empresa parceira usando o ID fornecido.
 *
 * @param {number} id - O ID da empresa parceira cujos representantes serão recuperados.
 * @return {Promise<Array>} Uma promessa que resolve para um array de representantes da empresa parceira.
 * @throws {Error} Se ocorrer algum erro durante a recuperação dos representantes da empresa.
 */
const getRepresentantesEmpresa = id => {
  if (id) {
    return axiosInstance
      .get(API_URL + `v1/parceiros/representantes/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(response => {
        // Retorna os dados dos representantes da empresa
        return response.data;
      })
      .catch(error => {
        // Loga o erro e lança uma exceção em caso de falha na requisição
        console.error("Erro ao obter os representantes da empresa: ", error);
        throw error;
      });
  } else {
    // Retorna um array vazio se o ID não for fornecido
    return [];
  }
};

/**
 * Exclui um representante de uma empresa parceira.
 *
 * @param {number} id - O ID do representante a ser excluído.
 * @param {string} motivo - O motivo da exclusão do representante.
 * @return {Promise<string>} Uma promessa que resolve para a mensagem de sucesso da exclusão.
 * @throws {Error} Se ocorrer algum erro durante a exclusão do representante.
 */
const excluiRepresentanteEmpresa = (id, motivo) => {
  return axiosInstance
    .delete(API_URL + `v1/parceiros/representantes/exclui/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: motivo,
    })
    .then(response => {
      return response.data.message;
    })
    .catch(error => {
      console.error("Erro ao tentar excluir representante: ", error);
      throw error;
    });
};

const postCadastrarVaga = data => {
  return axiosInstance
    .post(API_URL + `v1/parceiros/vaga/cadastro`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      console.log("Service parceiro", response.data);
      return response.data.message;
    })
    .catch(error => {
      console.error("Error cadastrando vaga:", error);
      throw error;
    });
};

const getVagas = () => {
  return axiosInstance
    .get(API_URL + `v1/parceiros/vagas`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error("Error fetching vagas:", error);
      throw error;
    });
};

const getVaga = id => {
  return axiosInstance
    .get(API_URL + `v1/parceiros/vaga/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error("Error fetching vaga:", error);
      throw error;
    });
};

const getOcupacoesCB2002 = () => {
  return axiosInstance
    .get(API_URL + `v1/trabalhador/cbo2002`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      // TODO: Remover limitação de 10
      const ocupacoes = response.data.slice(0, 10).map(ocupacao => ({
        id: ocupacao.id_cbo2002.toString(),
        descricao: ocupacao.nome_ocupacao,
      }));

      return ocupacoes;
    })
    .catch(error => {
      console.error("Error fetching vagas:", error);
      throw error;
    });
};

const parceiroService = {
  getParceiroData,
  getEmpresaData,
  postCadastrarEmpresa,
  getEmpresasParceiras,
  excluiEmpresaParceira,
  atualizaEmpresaParceira,
  getEmpresasParceirasComDetalhes,
  postCadastrarEmpresaComplementar,
  getEmpresaComplementar,
  atualizaEmpresaComplementar,
  postInsereArquivo,
  postCadastrarCurso,
  getCursos,
  getCurso,
  getTrilhasCursos,
  postRepresentanteEmpresa,
  getRepresentantesEmpresa,
  excluiRepresentanteEmpresa,
  postCadastrarVaga,
  getVagas,
  getVaga,
  getOcupacoesCB2002,
};

export default parceiroService;
