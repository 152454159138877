import React from "react";
import { useNavigate } from "react-router-dom";

import { Container } from "@mui/material";
import { useQueries } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import VagaTrabalho from "../../components/formularios/VagaTrabalho";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import parceiroService from "../../services/parceiro.service";
import { transformaParaBackend } from "../../utils/parceiroVagaUtils";

const valoresIniciais = {
  tipoVaga: "",
  cnpj: "",
  nomeOfertante: "",
  funcao: "",
  quantidadeVagas: "",
  validadeAnuncio: "",
  escolaridadeRequerida: "",
  escolaridadeMinima: false,
  vagaPCD: false,
  localTrabalhoParceiro: true,
  salario: "",
  turno: "",
  descricao: "",
  // uf: "",
  // municipio: "",
  // cep: "",
  // endereco: "",
  // numero: "",
  // complemento: "",
  // referencia: "",
  // bairro: "",
  auxilioAlimentacao: false,
  auxilioTransporte: false,
  planoSaude: false,
  outros: false,
  detalheAuxilioAlimentacao: "",
  detalheAuxilioTransporte: "",
  detalhePlanoSaude: "",
  detalheOutros: "",
  areaAtuacaoProfissional: [],
  areaAtuacaoEstudante: [],
  cursoComplementar: [],
  cursoTecnico: [],
  cursoSuperior: [],
};

const PageNovaVagaTrabalho = () => {
  const navigate = useNavigate();

  // Garantir que ambas as consultas sejam feitas simultaneamente e os arrays não sejam undefined
  const results = useQueries({
    queries: [
      {
        queryKey: ["empresasParceiras"],
        queryFn: parceiroService.getEmpresasParceiras,
      },
      {
        queryKey: ["ocupacoes"],
        queryFn: parceiroService.getOcupacoesCB2002,
      },
    ],
  });

  // Desestruturação dos resultados das queries
  const [empresasQuery, ocupacoesQuery] = results;

  // Dados carregados com sucesso
  const empresas = empresasQuery.data;
  // Atenção: está com uma limitação de 10 valores apenas, pois são mais de 2700 registros
  const ocupacoes = ocupacoesQuery.data;

  // Variaveis de estado
  const isError = empresasQuery.isError || ocupacoesQuery.isError;
  const isPending = empresasQuery.isPending || ocupacoesQuery.isPending;

  //TODO: get de cursos?
  const listaCursos = {
    complementar: [
      { id: "1", descricao: "Administração" },
      { id: "2", descricao: "Engenharia" },
      { id: "3", descricao: "Informática" },
      { id: "5", descricao: "Saúde" },
    ],
    tecnico: [
      { id: "1", descricao: "Administração" },
      { id: "2", descricao: "Engenharia" },
      { id: "3", descricao: "Informática" },
      { id: "5", descricao: "Saúde" },
    ],
    superior: [
      { id: "1", descricao: "Administração" },
      { id: "2", descricao: "Engenharia" },
      { id: "3", descricao: "Informática" },
      { id: "5", descricao: "Saúde" },
    ],
  };

  const handleSubmit = values => {
    console.log(values);

    const vagaPayload = transformaParaBackend(values);

    console.log(vagaPayload);

    return parceiroService
      .postCadastrarVaga(vagaPayload)
      .then(resposta => {
        console.log("Sucesso ao cadastrar vaga: ", resposta);
        navigate("/parceiro/vagas/listar");
      })
      .catch(erro => {
        console.error("Erro ao cadastrar vaga", erro);
      });
  };

  if (isError) {
    console.error("Erro de carregamento");
  }

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          {
            href: "/",
            text: "Página Inicial",
          },
          {
            href: "/parceiro",
            text: "Parceiro",
          },
        ]}
        currentPage={"Cadastrar Vaga de Trabalho"}
      />

      <TitlePage title={"Dados da vaga"} />

      {isPending ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
          widescreen={true}
        />
      ) : (
        <VagaTrabalho
          initialValues={valoresIniciais}
          onSubmit={handleSubmit}
          listaEmpresas={empresas}
          listaAreaAtuacao={ocupacoes}
          listaCursos={listaCursos}
        />
      )}

      {isError && <ErroCarregamentoModal isShow={true} />}
    </Container>
  );
};

export default PageNovaVagaTrabalho;
