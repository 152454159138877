import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Card, CardContent, FormControl, FormGroup, Grid, InputLabel, Select, Stack, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import adminService from "../../services/admin.service";
import ErroCarregamentoModal from "../errors/ErrorModal";
import CustomDatePicker from "../formularios/CustomDatePicker";
import LoadingComponent from "../loading/LoadingComponent";
import ServicoOfertadoModal from "../modals/ServicoOfertadoModal";
import DefaultTable from "../table/DefaultTable";

// Retornar a pesquisa vazia
const initialFilterState = {
  razaoSocial: "",
  nomeFantasia: "",
  nomeResponsavel: "",
  nomePontoFocal: "",
  tipoDeServico: [],
  dataCadastroInicio: null,
  dataCadastroFim: null,
  dataUltimaModificacaoInicio: null,
  dataUltimaModificacaoFim: null,
};

const tabelaColunas = [
  { field: "habilitacao", headerName: "Habilitação", sxRowProps: { textTransform: "uppercase", color: "primary.main" } },
  { field: "status", headerName: "Status" },
];

const keysHidden = [
  "dataCadastro",
  "dataUltimaModificacao",
  "cnpj",
  "nomeFantasia",
  "razaoSocial",
  "naturezaJuridica",
  "nomeResponsavel",
  "email",
  "nomePontoFocal",
  "emailPontoFocal",
  "telefone",
  "complemento",
  "uf",
  "cidade",
  "endereco",
];

const filterDataByStatus = (data, statuses) => {
  return data.filter(item => statuses.includes(item.status));
};

const AdminListarParceirosPendentes = () => {
  const navigate = useNavigate();
  const [servicosModal, setServicosModal] = useState(false);
  const [filter, setFilter] = useState(initialFilterState);
  const [filteredData, setFilteredData] = useState([]);
  const [parceiroSelecionado, setParceiroSelecionado] = useState({});

  // Conexão com o back-end
  const {
    isPending,
    isError,
    data: initialData,
    error,
  } = useQuery({
    queryKey: ["empresasParceirasPendentes"],
    queryFn: adminService.getEmpresasParceiras,
  });

  useEffect(() => {
    if (initialData) {
      setFilteredData(filterDataByStatus(Array.isArray(initialData) ? initialData : [], ["Novo cadastro", "Em cadastramento", "Pendente"]));
    }
  }, [initialData]);

  const tabelaHiddenLinhas = useMemo(
    () =>
      (Array.isArray(filteredData) ? filteredData : []).map(row =>
        keysHidden.reduce((acc, key) => {
          acc[key] = row[key];
          return acc;
        }, {}),
      ),
    [filteredData, keysHidden],
  );

  // -----------------------------------------------------------/

  const handleFilterChange = event => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };

  const handleFilterSelectChange = event => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: typeof value === "string" ? value.split(",") : value });
  };

  const applyFilter = () => {
    const filtered = initialData.filter(parceiro => {
      if (["Novo cadastro", "Pendente"].includes(parceiro.situacao)) {
        const matchesTextFilter =
          parceiro.nomeResponsavel.toLowerCase().includes(filter.nomeResponsavel.toLowerCase()) &&
          parceiro.nomePontoFocal.toLowerCase().includes(filter.nomePontoFocal.toLowerCase()) &&
          parceiro.nomeFantasia.toLowerCase().includes(filter.nomeFantasia.toLowerCase()) &&
          parceiro.razaoSocial.toLowerCase().includes(filter.razaoSocial.toLowerCase()) &&
          (filter.tipoDeServico.length === 0 ||
            Object.keys(parceiro.tipoDeServico)
              .map(servico => {
                return parceiro.tipoDeServico[servico] == true && filter.tipoDeServico.includes(servico);
              })
              .includes(true));

        const matchesDateFilter =
          (!filter.dataCadastroInicio ||
            !filter.dataCadastroFim ||
            dayjs(parceiro.dataCadastro).isBetween(filter.dataCadastroInicio, filter.dataCadastroFim, "day", "[]")) &&
          (!filter.dataUltimaModificacaoInicio ||
            !filter.dataUltimaModificacaoFim ||
            dayjs(parceiro.dataUltimaModificacao).isBetween(
              filter.dataUltimaModificacaoInicio,
              filter.dataUltimaModificacaoFim,
              "day",
              "[]",
            ));

        return matchesTextFilter && matchesDateFilter;
      }
    });
    setFilteredData(filtered);
  };

  const resetFilter = () => {
    setFilter(initialFilterState);
    applyFilter();
  };

  const handleServicoModalOpen = rowId => {
    setParceiroSelecionado(filteredData.filter(parceiro => parceiro.id == rowId)[0]);
    setServicosModal(true);
  };

  const handleServicoModalClose = () => {
    setServicosModal(false);
  };

  const getTabelaActions = row => {
    const actions = [
      {
        title: "Serviços Ofertados",
        href: "",
        icon: "list",
        onClick: rowId => handleServicoModalOpen(rowId),
      },
      {
        title: "Analisar",
        icon: "check_circle",
        onClick: rowId => navigate("/parceiro/analisar/" + rowId),
      },
      {
        title: "Visualizar",
        icon: "visibility",
        onClick: rowId => navigate("/parceiro/visualizar/" + rowId),
      },
    ];

    if (row?.empresaComplementar) {
      // Usando unshift pois quero esse valor no começo do array
      actions.unshift(
        {
          title: "Visualizar Informações Complementares",
          icon: "description",
          onClick: rowId => navigate("/parceiro/empresa-complementar/visualizar/" + rowId),
        },
        {
          title: "Analisar Informações Complementares",
          icon: "post_add",
          onClick: rowId => navigate("/parceiro/empresa-complementar/analisar/" + rowId),
        },
      );
    }

    return actions;
  };

  if (isPending) {
    return (
      <LoadingComponent
        isLoading={isPending}
        fullScreen={false}
        widescreen={true}
      />
    );
  }

  if (isError) {
    console.error("Erro de carregamento: " + error);
    return <ErroCarregamentoModal isShow={true} />;
  }

  return (
    <div
      onKeyDown={e => {
        if (e.key === "Enter") {
          applyFilter();
        }
      }}
    >
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          {/* Campos para filtrar */}
          <Grid
            spacing={3}
            container
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormGroup>
                <TextField
                  id="razaoSocial"
                  name="razaoSocial"
                  label="Razão Social"
                  placeholder="Razão Social"
                  value={filter.razaoSocial}
                  type="text"
                  onChange={handleFilterChange}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormGroup>
                <TextField
                  id="nomeFantasia"
                  name="nomeFantasia"
                  label="Nome Fantasia"
                  placeholder="Nome Fantasia"
                  value={filter.nomeFantasia}
                  type="text"
                  onChange={handleFilterChange}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormGroup>
                <TextField
                  id="nomeResponsavel"
                  name="nomeResponsavel"
                  label="Nome Responsável"
                  placeholder="Nome Responsável"
                  value={filter.nomeResponsavel}
                  type="text"
                  onChange={handleFilterChange}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormGroup>
                <TextField
                  id="nomePontoFocal"
                  name="nomePontoFocal"
                  label="Nome Ponto Focal"
                  placeholder="Nome Ponto Focal"
                  value={filter.nomePontoFocal}
                  type="text"
                  onChange={handleFilterChange}
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Grid
            mt={1}
            spacing={3}
            container
          >
            <Grid
              item
              xs={12}
              sm={12}
            >
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="label-tipo-servico">Tipo de Serviço</InputLabel>
                <Select
                  id="tipoDeServico"
                  name="tipoDeServico"
                  multiple
                  value={filter.tipoDeServico || ""}
                  type="text"
                  onChange={handleFilterSelectChange}
                  labelId="label-tipo-servico"
                >
                  <MenuItem
                    value="none"
                    disabled
                  >
                    Selecione uma opção
                  </MenuItem>
                  <MenuItem value="VEP">Vaga de Emprego</MenuItem>
                  <MenuItem value="VET">Vaga de Estágio</MenuItem>
                  <MenuItem value="VJA">Vaga de Jovem Aprendiz</MenuItem>
                  <MenuItem value="CUR">Cursos</MenuItem>
                  <MenuItem value="FPG">Financeiros e de Pagamentos</MenuItem>
                  <MenuItem value="MPU">Mobilização de Público</MenuItem>
                  <MenuItem value="MPA">Mobilização de Parceiro</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Card style={{ border: "1px solid #d3d3d3" }}>
                <CardContent>
                  <p style={{ fontFamily: "Rawline Regular", color: "grey" }}>Data do Cadastro</p>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={6}
                      sm={6}
                    >
                      <CustomDatePicker
                        label="Início"
                        id="dataCadastroInicio"
                        name="dataCadastroInicio"
                        value={filter.dataCadastroInicio}
                        format="DD/MM/YYYY"
                        onChange={valor => setFilter({ ...filter, ["dataCadastroInicio"]: valor })}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                    >
                      <CustomDatePicker
                        id="dataCadastroFim"
                        name="dataCadastroFim"
                        value={filter.dataCadastroFim}
                        label="Fim"
                        format="DD/MM/YYYY"
                        onChange={valor => setFilter({ ...filter, ["dataCadastroFim"]: valor })}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Card style={{ border: "1px solid #d3d3d3" }}>
                <CardContent>
                  <p style={{ fontFamily: "Rawline Regular", color: "grey" }}>Data da última modificação</p>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={6}
                      sm={6}
                    >
                      <CustomDatePicker
                        id="dataUltimaModificacaoInicio"
                        name="dataUltimaModificacaoInicio"
                        value={filter.dataUltimaModificacaoInicio}
                        label="Início"
                        format="DD/MM/YYYY"
                        onChange={valor => setFilter({ ...filter, ["dataUltimaModificacaoInicio"]: valor })}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                    >
                      <CustomDatePicker
                        id="dataUltimaModificacaoFim"
                        name="dataUltimaModificacaoFim"
                        value={filter.dataUltimaModificacaoFim}
                        label="Fim"
                        format="DD/MM/YYYY"
                        onChange={valor => setFilter({ ...filter, ["dataUltimaModificacaoFim"]: valor })}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Botões de pesquisa/cancelar */}
          <Box sx={{ flexGrow: 1, mt: "16px" }}>
            <Grid
              container
              spacing={1}
              justifyContent="flex-end"
            >
              <Grid
                item
                xs={12}
                sm={"auto"}
              >
                <Button
                  variant="outlined"
                  onClick={resetFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                  fullWidth
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={"auto"}
              >
                <Button
                  variant="contained"
                  onClick={applyFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                  fullWidth
                >
                  <SearchIcon />
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
          </Box>

          {/* Tabela */}
          <Box sx={{ flexGrow: 1, mt: "40px" }}>
            <DefaultTable
              rows={filteredData}
              notFoundText={"Não foi localizado Parceiro na situação pendente de aprovação!"}
              columns={tabelaColunas}
              hiddenRows={tabelaHiddenLinhas}
              actionButtons={getTabelaActions}
            ></DefaultTable>
          </Box>
        </Card>
      </Stack>
      <div>
        {/* Modal para listar Serviços*/}
        <ServicoOfertadoModal
          showModal={servicosModal}
          handleClose={handleServicoModalClose}
          tipoDeServico={parceiroSelecionado?.tipoDeServico}
        />
      </div>
    </div>
  );
};

AdminListarParceirosPendentes.propTypes = {
  setServicosModal: PropTypes.func,
  servicosModal: PropTypes.bool,
};

export default AdminListarParceirosPendentes;
