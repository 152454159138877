import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Card, Container, FormGroup, Grid, Stack, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import CustomDatePicker from "../../components/formularios/CustomDatePicker";
// import {
//   dadosParceirosPendentes,
//   tabelaCursosPendentes,
//   tabelaRepresentantes,
//   tabelaVagas,
// } from "../../components/formularios/dadosMockados";
import { formatCNPJ } from "../../components/formularios/utils.js";
import LoadingComponent from "../../components/loading/LoadingComponent.js";
import DefaultTable from "../../components/table/DefaultTable";
import TitlePage from "../../components/title-page/TitlePage";

// Primeira Tabela
const tabelaRepresentantesColunas = [
  { field: "nome", headerName: "Nome" },
  { field: "cpf", headerName: "CPF" },
  { field: "email", headerName: "Email" },
];

// TODO: Pegar do Back
const tabelaRepresentantesLinhas = [];

// Segunda Tabela
const tabelaVagasColunas = [
  { field: "nome", headerName: "Nome" },
  { field: "quantidade", headerName: "Quantidade" },
  { field: "descricao", headerName: "Descrição" },
];

// TODO: Pegar do Back
const tabelaVagasLinhas = [];

const tabelaVagasHiddenLinhas = tabelaVagasLinhas.map(({ salario, dataCadastro, vigencia }) => ({
  salario,
  dataCadastro,
  vigencia,
}));

// Terceira Tabela
const tabelaCursosColunas = [
  { field: "nome", headerName: "Nome" },
  { field: "inicioPreMatricula", headerName: "Início Pré-Matrícula" },
  { field: "fimPreMatricula", headerName: "Fim Pré-Matrícula" },
];

// TODO: Pegar do Back
const tabelaCursosLinhas = [];

const tabelaCursosHiddenLinhas = tabelaCursosLinhas.map(({ vagas, interessados, situacao, status }) => ({
  vagas,
  interessados,
  situacao,
  status,
}));

// Quarta Tabela
const tabelaPublicoMobilizadoColunas = [
  { field: "nome", headerName: "Nome", width: 250 },
  { field: "cnpj", headerName: "CNPJ", width: 200 },
  { field: "nomeResponsavel", headerName: "Nome do Responsável", width: 250 },
];

// TODO: Pegar do Back
const tabelaPublicoMobilizadoLinhas = [
  // {
  //   id: 1,
  //   nome: "Empresa Alpha",
  //   cnpj: "12.345.678/0001-99",
  //   nomeResponsavel: "Ana Silva",
  //   uf: "SP",
  //   cidade: "São Paulo",
  //   vagasCadastradas: 10,
  //   candidatosInteressados: 50,
  //   curriculosCadastrados: 40,
  //   pessoasInteressadasEmCurso: 20,
  // },
  // {
  //   id: 2,
  //   nome: "Empresa Beta",
  //   cnpj: "98.765.432/0001-88",
  //   nomeResponsavel: "Bruno Souza",
  //   uf: "RJ",
  //   cidade: "Rio de Janeiro",
  //   vagasCadastradas: 8,
  //   candidatosInteressados: 30,
  //   curriculosCadastrados: 25,
  //   pessoasInteressadasEmCurso: 15,
  // },
  // {
  //   id: 3,
  //   nome: "Empresa Gamma",
  //   cnpj: "23.456.789/0001-77",
  //   nomeResponsavel: "Carla Pereira",
  //   uf: "MG",
  //   cidade: "Belo Horizonte",
  //   vagasCadastradas: 12,
  //   candidatosInteressados: 40,
  //   curriculosCadastrados: 35,
  //   pessoasInteressadasEmCurso: 18,
  // },
];

const tabelaPublicoMobilizadoHiddenLinhas = tabelaPublicoMobilizadoLinhas.map(
  ({ uf, cidade, vagasCadastradas, candidatosInteressados, curriculosCadastrados, pessoasInteressadasEmCurso }) => ({
    uf,
    cidade,
    vagasCadastradas,
    candidatosInteressados,
    curriculosCadastrados,
    pessoasInteressadasEmCurso,
  }),
);

const VisualizarParceiroPendente = () => {
  const navigate = useNavigate();
  const [valores, setValores] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  let { id } = useParams();
  // TODO: remover esse default
  const parceiroID = id || "1";

  // const initialData = dadosParceirosPendentes;

  useEffect(() => {
    if (parceiroID) {
      // TODO : chamar backend procurando pelo ID = parseInt(parceiroID, 10)
      const parceiroEncontrado = { tipoDeServico: {} };

      if (parceiroEncontrado) {
        const servicos = Object.keys(parceiroEncontrado.tipoDeServico).filter(servico => parceiroEncontrado.tipoDeServico[servico]);

        setValores({
          ...parceiroEncontrado,
          tipoDeServico: servicos,
        });
      }
    }

    setIsLoading(false);
  }, []);

  // Segunda tabela
  const getTabelaVagasActions = () => [
    {
      title: "Visualizar dados da vaga",
      href: "",
      icon: "visibility",
    },

    {
      title: "Visualizar a relação de pessoas interessadas na vaga",
      onClick: rowId => navigate("/vagas/listar/interessadas/" + rowId),
      icon: "person",
    },
  ];

  // Terceira tabela
  const getTabelaCursosActions = () => [
    {
      title: "Visualizar dados do curso",
      icon: "visibility",
      onClick: rowId => navigate("/cursos/visualizar/" + rowId),
    },
    {
      title: "Visualizar a relação de pessoas interessadas no curso",
      icon: "person",
      onClick: rowId => navigate("/cursos/listar/interessadas/" + rowId),
    },
    {
      title: "Visualizar a decisão do curso",
      href: "",
      icon: "list",
    },
  ];

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/parceiro/listar/pendentes", text: "Listar Parceiros Pendentes" }]}
        currentPage="Visualizar Parceiro Pendente"
      />

      <TitlePage title="Visualizar Parceiro Pendente" />

      {isLoading ? (
        <LoadingComponent
          isLoading={isLoading}
          fullScreen={false}
          widescreen={true}
        />
      ) : (
        <Stack spacing={2}>
          <Card
            color="#ffffff"
            sx={{
              borderRadius: "8px",
              padding: "16px",
              mt: "24px",
            }}
          >
            <Grid
              spacing={1}
              container
            >
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormGroup>
                  <TextField
                    id="nomeResponsavel"
                    name="nomeResponsavel"
                    label="Nome"
                    placeholder="Nome"
                    value={valores.nomeResponsavel}
                    type="text"
                    disabled
                  />
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormGroup>
                  <TextField
                    id="cnpj"
                    name="cnpj"
                    label="CNPJ"
                    placeholder="CNPJ"
                    value={formatCNPJ(valores.cnpj) || ""}
                    type="text"
                    disabled
                  />
                </FormGroup>
              </Grid>
            </Grid>

            <Grid
              spacing={1}
              container
              marginTop={1}
            >
              <Grid
                item
                xs={12}
                sm={4}
              >
                <CustomDatePicker
                  id="dataCadastro"
                  name="dataCadastro"
                  label="Data do cadastro"
                  value={dayjs(valores.dataCadastro)}
                  format="DD/MM/YYYY"
                  onChange={null}
                  disabled
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                <CustomDatePicker
                  label="Ultima Modificação"
                  id="dataUltimaModificacao"
                  name="dataUltimaModificacao"
                  value={dayjs(valores.dataUltimaModificacao)}
                  format="DD/MM/YYYY"
                  disabled
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                <FormGroup>
                  <TextField
                    id="status"
                    name="status"
                    label="Situação"
                    placeholder="Situação"
                    value={valores.status}
                    type="text"
                    disabled
                  />
                </FormGroup>
              </Grid>
            </Grid>

            <Grid
              spacing={1}
              container
              marginTop={1}
            >
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormGroup>
                  <TextField
                    id="nomePontoFocal"
                    name="nomePontoFocal"
                    label="Ponto focal"
                    placeholder="Ponto focal"
                    value={valores.nomePontoFocal}
                    type="text"
                    disabled
                  />
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormGroup>
                  <TextField
                    id="emailPontoFocal"
                    name="emailPontoFocal"
                    label="E-mail do ponto focal"
                    placeholder="E-mail do ponto focal"
                    value={valores.emailPontoFocal}
                    type="text"
                    disabled
                  />
                </FormGroup>
              </Grid>
            </Grid>

            <Typography
              variant="h5"
              mt={2}
              sx={{ fontFamily: "Rawline Bold", marginBottom: "10px", marginTop: "24px" }}
              style={{ transition: "1s" }}
            >
              Dados dos reprentantes
            </Typography>

            <DefaultTable
              columns={tabelaRepresentantesColunas}
              rows={tabelaRepresentantesLinhas}
            ></DefaultTable>
          </Card>

          <Card
            color="#ffffff"
            sx={{
              borderRadius: "8px",
              padding: "16px",
              mt: "24px",
            }}
          >
            <Typography
              variant="h4"
              mt={2}
              sx={{ fontFamily: "Rawline Bold", marginBottom: "10px" }}
              style={{ transition: "1s" }}
            >
              Serviço(s) ofertado(s) pelo parceiro (emprego, curso, mobilização de público, mobilização de parceiro, estágio, voluntariado)
            </Typography>

            <Typography
              variant="h5"
              mt={2}
              sx={{ fontFamily: "Rawline Bold", marginBottom: "10px" }}
              style={{ transition: "1s" }}
            >
              Vagas cadastradas
            </Typography>

            <DefaultTable
              columns={tabelaVagasColunas}
              rows={tabelaVagasLinhas}
              hiddenRows={tabelaVagasHiddenLinhas}
              actionButtons={getTabelaVagasActions}
            ></DefaultTable>

            <Typography
              variant="h5"
              mt={2}
              sx={{ fontFamily: "Rawline Bold", marginBottom: "10px", marginTop: "40px" }}
              style={{ transition: "1s" }}
            >
              Cursos Ofertados
            </Typography>

            <DefaultTable
              columns={tabelaCursosColunas}
              rows={tabelaCursosLinhas}
              hiddenRows={tabelaCursosHiddenLinhas}
              actionButtons={getTabelaCursosActions}
            ></DefaultTable>
          </Card>

          <Card
            color="#ffffff"
            sx={{
              borderRadius: "8px",
              padding: "16px",
              mt: "24px",
            }}
          >
            <Typography
              variant="h5"
              mt={2}
              sx={{ fontFamily: "Rawline Bold", marginBottom: "10px" }}
              style={{ transition: "1s" }}
            >
              Dados de público mobilizado
            </Typography>

            <Grid
              spacing={1}
              container
              mb={2}
            >
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormGroup>
                  <TextField
                    id="quantidadeCurriculos"
                    name="quantidadeCurriculos"
                    label="Quantidade de Currículos Inseridos"
                    placeholder="Quantidade de Currículos Inseridos"
                    value="100"
                    type="text"
                    disabled
                  />
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormGroup>
                  <TextField
                    id="quantidadeUsuarios"
                    name="quantidadeUsuarios"
                    label="Quantidade de Usuários Encontrados Interessados em Cursos"
                    placeholder="Quantidade de Usuários Encontrados Interessados em Cursos"
                    value="200"
                    type="text"
                    disabled
                  />
                </FormGroup>
              </Grid>
            </Grid>

            <DefaultTable
              columns={tabelaPublicoMobilizadoColunas}
              rows={tabelaPublicoMobilizadoLinhas}
              hiddenRows={tabelaPublicoMobilizadoHiddenLinhas}
            ></DefaultTable>
          </Card>

          <Grid
            container
            justifyContent="flex-end"
          >
            <Grid item>
              <Button
                variant="outlined"
                href="/parceiro/listar/pendentes"
              >
                <Typography variant={"BUTTON TEXT"}>CANCELAR</Typography>
              </Button>
            </Grid>
          </Grid>
        </Stack>
      )}
    </Container>
  );
};

export default VisualizarParceiroPendente;
