import React from "react";
import { useParams } from "react-router-dom";

import { Container } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import FormCurso from "../../components/formularios/FormCurso";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import parceiroService from "../../services/parceiro.service";

const PageVisualizarCurso = () => {
  let { id } = useParams();

  // Conexão com o back-end
  const {
    isPending,
    isError,
    data: initialData,
    // error,
  } = useQuery({
    queryKey: ["Curso", id],
    queryFn: ({ queryKey }) => {
      const [, id] = queryKey;
      return parceiroService.getCurso(id);
    },
    enabled: !!id,
  });

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/cursos", text: "Cursos" }]}
        currentPage={"Visualizar"}
      />

      <TitlePage title={"Visualizar Dados do Curso"} />

      {isPending ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
        />
      ) : isError ? (
        <ErroCarregamentoModal isShow={true} />
      ) : (
        <FormCurso
          initialValues={initialData}
          visualizacao={true}
        />
      )}
    </Container>
  );
};

export default PageVisualizarCurso;
