const adminNavConfig = [
  {
    title: "Página Inicial",
    path: "/admin",
    color: "default",
    subTitles: {
      home: "/",
      perfil: "/profile",
    },
  },
  {
    title: "Parceiros",
    path: "/parceiros",
    subTitles: {
      "Listar Parceiros Pendentes": "/parceiro/listar/pendentes",
      "Listar Parceiros Aprovados": "/parceiro/listar/aprovados",
      "Listar Parceiros Reprovados": "/parceiro/listar/reprovados",
      "Listar Representantes do Parceiro": "/parceiro/representantes",
    },
  },
  {
    title: "Cursos",
    path: "/cursos",
    subTitles: {
      "Listar Cursos Pendentes": "/listar-cursos-pendentes",
      "Listar Cursos Aprovados": "/listar-cursos-aprovados",
      "Listar Cursos Reprovados": "/listar-cursos-reprovados",
      "Listar Cursos com Site Inativo": "/listar-cursos-site-inativo",
      "Listar Trilhas": "/trilhas/listar",
    },
  },
  {
    title: "Microcrédito",
    path: "microcredito",
    subTitles: {
      "Já Sou Empreendedor (HU133)": "/admin/microcredito/ja-sou-empreendedor",
      "Quero Ser Empreendedor (HU134)": "/admin/microcredito/quero-ser-empreendedor",
    },
  },
  {
    title: "Trilhas",
    path: "trilhas",
    subTitles: {
      Listar: "/trilhas/listar",
      "Nova trilha": "/trilhas/novo/",
    },
  },
  {
    title: "[DEV] Components",
    path: "/components",
    color: "default",
    subTitles: {
      Components: "/components",
      "Default Table": "/components-default-table",
      "Dynamic Form": "/components-form",
    },
  },
  {
    title: "Menu Temporário",
    path: "/",
    color: "default",
    subTitles: {
      "Listar Parceiros Pendentes (HU002)": "/parceiro/listar/pendentes",
      "Analisar Parceiro Pendente (HU003, HU005, HU006, HU007)": "/parceiro/analisar",
      "Visualizar Parceiro Pendente (HU004)": "/parceiro/visualizar",
      //"Cadastrar Informações Complementares (HU127)": "/parceiro/empresa-complementar/cadastrar",
      "Analisar Informações Complementares (HU128)": "/parceiro/empresa-complementar/analisar",
      "Visualizar Informações Complementares (HU129)": "/parceiro/empresa-complementar/visualizar",
      "Listar pessoas interessadas (HU008)": "/vagas/listar/interessadas",
      "Currículo (HU009)": "/vagas/listar/interessadas/curriculo",
      "Listar Parceiros Aprovados (HU010)": "/parceiro/listar/aprovados",
      "Listar Parceiros Reprovados (HU011)": "/parceiro/listar/reprovados",
      "Listar interessados no curso (HU012)": "/cursos/listar/interessadas",
      "Visualizar curso (HU013)": "/cursos/visualizar",
      "Visualizar Alterações (HU014)": "/parceiro/alteracoes/visualizar",
      "Visualizar Alterações Documentos (HU015)": "/parceiro/alteracoes-documentos/visualizar",
      "Listar Representantes do Parceiro (HU020)": "/parceiro/representantes",
      "Inativar Parceiro (HU021)": "/parceiro/reprovados/inativar",
      //"Editar Parceiro (HU022)": "/parceiro/editar",
      "Listar Cursos Pendentes (HU025, HU028)": "/listar-cursos-pendentes",
      "Analisar Curso (HU026)": "/listar-cursos-pendentes/analisar",
      "Vizualizar Alterações Curso (HU027)": "/listar-cursos-pendentes/analisar/alteracoes",
      "Listar Cursos Aprovados (HU029, HU030)": "listar-cursos-aprovados",
      "Listar Cursos Reprovados (HU031, HU032)": "/listar-cursos-reprovados",
      "Listar Cursos com Site Inativo (HU039)": "/listar-cursos-site-inativo",
      "Histórico Alterações Curso (HU040)": "/listar-cursos-pendentes/analisar/historico",
      "Histórico Cursos (HU041)": "/listar-cursos-pendentes/historico",
      "Nova Trilha (HU042)": "/trilhas/novo",
      "Listar Trilhas (HU043, HU045, HU046, HU047)": "/trilhas/listar",
      "Editar Trilha (HU044)": "/trilhas/editar",
      "Visualizar Trilha (HU048)": "/trilhas/visualizar",
      "Incluir Curso (HU049, HU050)": "/trilhas/incluir-cursos",
      "Listar Anexos Curso (HU051)": "/listar-anexos-curso",
      "Listar Vagas (HU063, HU065)": "/listar-vagas",
      "Visualizar Vagas (HU064)": "/listar-vagas/visualizar",
      "Critérios Vaga (HU067)": "/listar-parceiros-aprovados/visualizar/criterios",
      "Listar usuários (HU073, HU075, HU076)": "/usuario/listar/",
      "Novo Usuário (HU072)": "/usuario/novo/",
      "Editar Usuário (HU074)": "/usuario/editar/",
      "Excluir Usuário (HU077)": "/usuario/excluir/",
      "Visualizar Usuário (HU078)": "/usuario/visualizar",
      "Histórico Situação Usuário (HU079)": "/usuario/visualizar/historico-situacao",
      "Listar Perfis (HU081, HU083, HU084)": "/perfil/listar/",
      "Novo Perfil (HU080)": "/perfil/novo/",
      "Editar Perfil (HU082)": "/perfil/editar/",
      "Excluir Perfil (HU085)": "/perfil/excluir/",
      "Visualizar Perfil (HU086)": "/perfil/visualizar/",
      "Histórico Situação Perfil (HU087)": "/perfil/visualizar/historico-situacao/",
      "Listar Interessados MPO (HU131, HU135)": "/listar-mpo",
      "Situação Interessado MPO (HU132)": "/listar-mpo/situacao",
      "Listar Interessados MPO por Parceiro (HU141)": "/listar-mpo-parceiro",
      "Consultar parceiro operador de microcrédito (HU142)": "/listar-mpo/consultar",
      "Consultar painel de indicadores (HU158)": "/consultar-painel-indicadores",
      "Gerar relatorio de indicadores (HU159)": "/gerar-relatorio-indicadores",
      "Vizualizar modelos de E-mail (HU154 - HU157, HU161)": "/emails",
      "Já Sou Empreendedor (HU133)": "/admin/microcredito/ja-sou-empreendedor",
      "Quero Ser Empreendedor (HU134)": "/admin/microcredito/quero-ser-empreendedor",
      "Alerta Admin sobre Pedido de Microcrédito (HU160)": "/parceiro/alerta-pedidos-microcredito",
      "Consultar Jornada do Cidadão (HU163)": "/parceiro/consultar-jornada-cidadao",
    },
  },
];

export default adminNavConfig;
