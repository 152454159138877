import React from "react";
import { useForm } from "react-hook-form";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import { tp_escolaridade } from "../../services/constants.js";
import { payloadQueroSerEmpreendedor } from "../../utils/formulariosUtils.js";
import InformationBox from "../boxes/InformationBox";
import CustomDatePicker from "./CustomDatePicker";
import { FormFieldsGrid } from "./DynamicForm.js";
import ReusableSwitch from "./fields/ReusableSwitch";
import FormAddress from "./FormAddress.js";
import FormMultipleInsertDouble from "./FormMultipleInsertDouble";
import { formatCPF, formatTelefone, validarCPF } from "./utils.js";

const valoresIniciais = {
  desejaAbrirNegocio: false,
  primeiraVezAbrirNegocio: false,
  checkDesempregado: false,
  checkSonho: false,
  checkQueroSerPatrao: false,
  checkQueroTerExperienciaEmpreender: false,
  checkQueroEmpreenderNovamente: false,
  checkProjetoFamiliar: false,
  checkEmpregado: false,
  setoresRadio: "",
  situacaoFuturaRadio: "",
  jaParticipouCurso: false,
  jaParticipouCursoDetalhes: [],
  gostariaParticiparCurso: false,
  gostariaReceberOrientacao: false,
  gostariaParticiparCursoMotivo: "",
  gostariaReceberOrientacaoMotivo: "",
  necessidadeFinanciamento: false,
  necessidadeFinanciamentoMotivo: "",
  conheceEmprestimos: false,
  nomeCompleto: "",
  dataNascimento: "",
  rg: "",
  cpf: "",
  generoRadio: "",
  escolaridade: "",
  // Agora usando o componente FormAddress
  enderecoCompleto: [],
  telefone: "",
  email: "",
};

const FormQueroSerEmpreendedor = ({ initialValues = valoresIniciais, visualizacao = false }) => {
  const theme = useTheme();

  const {
    control,
    handleSubmit,
    getValues,
    // setValue,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: initialValues,
    mode: "onBlur",
  });

  const fieldsNegocio = [
    {
      name: "desejaAbrirNegocio",
      label: "Você deseja abrir seu próprio negócio?",
      renderComponent: ({ value, onChange }) => (
        <>
          <span style={{ color: "red", fontWeight: "bold" }}>
            {"[DEV]: atenção todos os campos são requeridos e devem apresentar o erro de validação!!! "}
          </span>
          <Typography variant="subtitle2">Você deseja abrir seu próprio negócio?</Typography>
          <ReusableSwitch
            id={"desejaAbrirNegocio"}
            name={"desejaAbrirNegocio"}
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "primeiraVezAbrirNegocio",
      label: "Você deseja abrir seu próprio negócio?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">É a primeira vez que você vai abrir o seu próprio negócio?</Typography>
          <ReusableSwitch
            id={"primeiraVezAbrirNegocio"}
            name={"primeiraVezAbrirNegocio"}
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkDesempregado",
      label: "Estou desempregado, preciso de alguma atividade que me gere renda",
      renderComponent: ({ value, onChange, error }) => (
        <>
          <Typography variant="subtitle2">Qual(is) o(s) motivo(s) que faz você desejar abrir seu próprio negócio?</Typography>
          <FormControl error={!!error}>
            <FormControlLabel
              control={
                <Checkbox
                  id="checkDesempregado"
                  name="checkDesempregado"
                  checked={value ?? false}
                  onChange={onChange}
                  style={{ color: theme.palette.text.disabled }}
                  disabled={visualizacao}
                />
              }
              label={<Typography>Estou desempregado, preciso de alguma atividade que me gere renda</Typography>}
            />
          </FormControl>
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkSonho",
      label: "É um sonho que quero realizar",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkSonho"
                name="checkSonho"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>É um sonho que quero realizar</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkQueroSerPatrao",
      label: "Quero ser meu próprio patrão",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkQueroSerPatrao"
                name="checkQueroSerPatrao"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>Quero ser meu próprio patrão</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkQueroTerExperienciaEmpreender",
      label: "Quero ter a experiência de empreender um negócio",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkQueroTerExperienciaEmpreender"
                name="checkQueroTerExperienciaEmpreender"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>Quero ter a experiência de empreender um negócio</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkQueroEmpreenderNovamente",
      label: "Quero empreender novamente",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkQueroEmpreenderNovamente"
                name="checkQueroEmpreenderNovamente"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>Quero empreender novamente</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkProjetoFamiliar",
      label: "É um projeto familiar, precisam da minha participação",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkProjetoFamiliar"
                name="checkProjetoFamiliar"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>É um projeto familiar, precisam da minha participação</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkEmpregado",
      label: "Apesar de estar empregado, preciso de um complemento da minha renda",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkEmpregado"
                name="checkEmpregado"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>Apesar de estar empregado, preciso de um complemento da minha renda</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "setoresRadio",
      label: "O seu futuro negócio está mais próximo de qual dos setores abaixo?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">O seu futuro negócio está mais próximo de qual dos setores abaixo?</Typography>
          <FormGroup>
            <RadioGroup
              name="setoresRadio"
              onChange={onChange}
              value={value || ""}
              disabled={visualizacao}
            >
              <FormControlLabel
                value="industria"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "industria"}
                    disabled={visualizacao}
                  />
                }
                label="Indústria (Você fabrica algo?)"
              />
              <FormControlLabel
                value="comercio"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "comercio"}
                    disabled={visualizacao}
                  />
                }
                label="Comércio (Você vende produtos?)"
              />
              <FormControlLabel
                value="servicos"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "servicos"}
                    disabled={visualizacao}
                  />
                }
                label="Serviços (Você é um prestador de serviços - hidráulica, pintura, alimentação, etc.)?"
              />
              <FormControlLabel
                value="outros"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "outros"}
                    disabled={visualizacao}
                  />
                }
                label="Outros"
              />
            </RadioGroup>
          </FormGroup>
        </>
      ),
      size: { sx: 12 },
    },
    {
      name: "situacaoFuturaRadio",
      label: "Qual a situação do seu negócio?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">Qual a situação do seu negócio?</Typography>
          <FormGroup>
            <RadioGroup
              name="situacaoFuturaRadio"
              onChange={onChange}
              value={value || ""}
              disabled={visualizacao}
            >
              <FormControlLabel
                value="informal"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "informal"}
                    disabled={visualizacao}
                  />
                }
                label="Informal"
              />
              <FormControlLabel
                value="formal"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "formal"}
                    disabled={visualizacao}
                  />
                }
                label="Formal - Microempresa"
              />
              <FormControlLabel
                value="formal-outros"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "formal-outros"}
                    disabled={visualizacao}
                  />
                }
                label="Formal - Outros portes"
              />
              <FormControlLabel
                value="formal-mei"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "formal-mei"}
                    disabled={visualizacao}
                  />
                }
                label="Formal - Microempreendedor Individual - MEI"
              />
            </RadioGroup>
          </FormGroup>
        </>
      ),
      size: { sx: 12 },
    },
    {
      name: "jaParticipouCurso",
      label: "Você já participou de algum curso sobre empreendedorismo, microcrédito, educação financeira?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">
            Você já participou de algum curso sobre empreendedorismo, microcrédito, educação financeira?
          </Typography>
          <ReusableSwitch
            id={"jaParticipouCurso"}
            name={"jaParticipouCurso"}
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "jaParticipouCursoDetalhes",
      label: "Cursos participados",
      renderComponent: ({ value, onChange }) => (
        <>
          <span style={{ color: "red" }}>{"[DEV]: só deve aparecer se o jaParticipouCurso for true "}</span>
          <FormMultipleInsertDouble
            label1={"Qual(is) Curso(s)?"}
            label2={"Qual a Instituição realizadora?"}
            value1={"curso"}
            value2={"instituicao"}
            disabled={visualizacao}
            values={value}
            onFieldsChange={onChange}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "gostariaParticiparCurso",
      label:
        "Você gostaria de participar de algum curso sobre empreendedorismo,  microcrédito, educação financeira, de outros assuntos para melhor gerir o seu negócio?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">
            Você gostaria de participar de algum curso sobre empreendedorismo, microcrédito, educação financeira, de outros assuntos para
            melhor gerir o seu negócio?
          </Typography>
          <ReusableSwitch
            id={"gostariaParticiparCurso"}
            name={"gostariaParticiparCurso"}
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "gostariaParticiparCursoMotivo",
      label: "Por que motivo?",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <span style={{ color: "red" }}>{"[DEV]: só deve aparecer se o gostariaParticiparCurso for true "}</span>
          <TextField
            label="Por que motivo?"
            id="gostariaParticiparCursoMotivo"
            name="gostariaParticiparCursoMotivo"
            value={value || ""}
            placeholder="Insira o motivo de querer participar"
            type="text"
            onChange={onChange}
            disabled={visualizacao}
          />
        </FormGroup>
      ),
      size: { xs: 12 },
    },
    {
      name: "gostariaReceberOrientacao",
      label: "Você gostaria de receber orientação sobre como administrar seu negócio?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">Você gostaria de receber orientação sobre como administrar seu negócio?</Typography>
          <ReusableSwitch
            id={"gostariaReceberOrientacao"}
            name={"gostariaReceberOrientacao"}
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "gostariaReceberOrientacaoMotivo",
      label: "Por que motivo?",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <span style={{ color: "red" }}>{"[DEV]: só deve aparecer se o gostariaReceberOrientacao for true "}</span>
          <TextField
            label="Por que motivo?"
            id="gostariaReceberOrientacaoMotivo"
            name="gostariaReceberOrientacaoMotivo"
            value={value || ""}
            placeholder="Insira o motivo de querer participar"
            type="text"
            onChange={onChange}
            disabled={visualizacao}
          />
        </FormGroup>
      ),
      size: { xs: 12 },
    },
  ];

  const fieldsVoce = [
    {
      name: "necessidadeFinanciamento",
      label: "Você tem necessidade de um financiamento/empréstimo de microcrédito?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">Você tem necessidade de um financiamento/empréstimo de microcrédito?</Typography>
          <ReusableSwitch
            id={"necessidadeFinanciamento"}
            name={"necessidadeFinanciamento"}
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "conheceEmprestimos",
      label:
        "Você conhece os empréstimos para grupos de microempreendedores que utilizam o chamado aval solidário como garantia de pagamento do empréstimo?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">
            Você conhece os empréstimos para grupos de microempreendedores que utilizam o chamado aval solidário como garantia de pagamento
            do empréstimo?
          </Typography>
          <ReusableSwitch
            id={"conheceEmprestimos"}
            name={"conheceEmprestimos"}
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "nomeCompleto",
      label: "Nome Completo",
      size: { xs: 12, sm: 6 },
    },
    {
      name: "dataNascimento",
      label: "Data de Nascimento",
      renderComponent: ({ value, onChange }) => (
        <>
          <CustomDatePicker
            label="Data de Nascimento"
            id="dataNascimento"
            name="dataNascimento"
            value={value ? dayjs(value) : null}
            format="DD/MM/YYYY"
            onChange={onChange}
            disabled={visualizacao}
          />
          {/* {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>} */}
        </>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "rg",
      label: "RG",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <TextField
            label="* RG"
            id="rg"
            name="rg"
            value={value}
            placeholder="Insira o RG"
            type="number"
            onChange={onChange}
            inputProps={{ maxLength: 15 }}
            disabled={visualizacao}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "cpf",
      label: "CPF",
      validation: {
        validate: {
          // required: "CPF  é obrigatório",
          tamanhoValido: value => {
            if (!value || value.trim() === "") {
              return true;
            }
            if (value.replace(/\D/g, "").length !== 11) {
              return "O CPF deve ter 11 dígitos";
            }
            return true;
          },
          cpfValido: value => {
            if (!value || value.trim() === "") {
              return true;
            }
            if (!validarCPF(value)) {
              return "O CPF informado não é válido";
            }
            return true;
          },
        },
      },
      renderComponent: ({ value, onChange, error }) => (
        <FormGroup>
          <TextField
            label="* CPF"
            id="cpf"
            name="cpf"
            value={formatCPF(value)}
            placeholder="Insira o CPF"
            type="text"
            onChange={onChange}
            inputProps={{ maxLength: 14 }}
            disabled={visualizacao}
            error={!!error}
            helperText={error?.message}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "generoRadio",
      label: "Gênero",
      renderComponent: ({ value, onChange }) => (
        <>
          {/* valores de tp_genero */}
          <Typography variant="subtitle2">Gênero</Typography>
          <FormGroup>
            <RadioGroup
              name="generoRadio"
              onChange={onChange}
              value={value || ""}
              disabled={visualizacao}
            >
              <FormControlLabel
                value="FEM"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "FEM"}
                    disabled={visualizacao}
                  />
                }
                label="Feminino"
              />
              <FormControlLabel
                value="MAS"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "MAS"}
                    disabled={visualizacao}
                  />
                }
                label="Masculino"
              />
              <FormControlLabel
                value="NIN"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "NIN"}
                    disabled={visualizacao}
                  />
                }
                label="Prefiro não informar"
              />
            </RadioGroup>
          </FormGroup>
        </>
      ),
      size: { sx: 12 },
    },
    {
      name: "escolaridade",
      label: "Escolaridade",
      renderComponent: ({ value, onChange }) => {
        return (
          <FormControl fullWidth>
            <InputLabel>Escolaridade</InputLabel>
            <Select
              id="escolaridade"
              name="escolaridade"
              value={value}
              onChange={onChange}
              disabled={visualizacao}
            >
              <MenuItem
                key={""}
                value={""}
                disabled
              >
                Selecione uma escolaridade
              </MenuItem>

              {Object.keys(tp_escolaridade).map(escolaridade => (
                <MenuItem
                  key={escolaridade}
                  value={escolaridade}
                >
                  {tp_escolaridade[escolaridade]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
      size: { xs: 12 },
    },
    {
      name: "enderecoCompleto",
      label: "Endereço",
      renderComponent: ({ value, onChange }) => (
        <FormAddress
          {...value}
          onChange={onChange}
        />
      ),
      size: { xs: 12, sm: 12 },
    },
    {
      name: "telefone",
      label: "* Telefone",
      validation: {
        // required: "Telefone é obrigatório",
        validate: {
          tamanhoValido: value => {
            // Se o valor estiver vazio, retorna true (não valida tamanho)
            if (!value || value.trim() === "") {
              return true;
            }
            const tamanho = value.replace(/\D/g, "").length;
            return tamanho === 10 || tamanho === 11 || "O telefone deve ter 10 ou 11 dígitos";
          },
        },
      },
      renderComponent: ({ value, onChange, error }) => (
        <FormGroup>
          <TextField
            label="* Telefone"
            id="telefone"
            name="telefone"
            value={formatTelefone(value)}
            placeholder="Insira o Telefone"
            type="text"
            onChange={onChange}
            inputProps={{ maxLength: 15 }}
            disabled={visualizacao}
            error={!!error}
            helperText={error?.message}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "email",
      label: "E-mail",
      validation: {
        // required: "E-mail é obrigatório",
        pattern: {
          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          message: "Insira um e-mail válido",
        },
      },
      renderComponent: ({ value, onChange, error }) => (
        <FormGroup>
          <TextField
            label="* E-mail"
            id="email"
            name="email"
            value={value}
            placeholder="Insira o E-mail"
            type="text"
            onChange={onChange}
            disabled={visualizacao}
            error={!!error}
            helperText={error?.message}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6 },
    },
  ];

  const handleKeyDown = event => {
    // Impedir que o formularário seja enviado ao pressionar a tecla Enter
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <form
      onSubmit={event => {
        event.preventDefault(); // Previne o comportamento padrão do formulário
        // handleSubmitAntigo(values); // Só para mostrar os dados antigos

        console.log("Valores reais do formulario:", getValues());

        // Chama o handleSubmit do React Hook Form, que valida e envia os dados
        handleSubmit(data => {
          const payload = payloadQueroSerEmpreendedor(data);

          console.log("Valores enviados para o backend:", payload);
        })();

        // Se você quer ver os erros de validação
        console.log("Erros de validação:", errors);
      }}
      onKeyDown={handleKeyDown}
    >
      <div>
        <Accordion style={{ border: "1px solid grey" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontFamily: "Rawline Bold", color: "primary.main" }}
          >
            <InfoIcon sx={{ marginRight: "8px", color: "primary.main" }} />
            Sobre o negócio
          </AccordionSummary>
          <AccordionDetails>
            <FormFieldsGrid
              fields={fieldsNegocio}
              control={control}
              errors={errors}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ border: "1px solid grey" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontFamily: "Rawline Bold", color: "primary.main" }}
          >
            <InfoIcon sx={{ marginRight: "8px", color: "primary.main" }} />
            Sobre você
          </AccordionSummary>
          <AccordionDetails>
            <FormFieldsGrid
              fields={fieldsVoce}
              control={control}
              errors={errors}
            />
          </AccordionDetails>
        </Accordion>
      </div>

      <InformationBox
        title="Atenção:"
        message="Confira todas as respostas antes de clicar no botão 'Salvar Respostas'. Após salvar, não será permitido alterar as respostas."
      />

      <Grid
        container
        spacing={1}
        justifyContent="flex-end"
        mt={2}
      >
        <Grid item>
          <Button
            variant="outlined"
            onClick={() => console.log("cancelar")}
            sx={{ gap: "8px" }}
            style={{ fontFamily: "Rawline Medium", textTransform: "uppercase" }}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            // onClick={() => onSubmit(getValues())}
            fullWidth
            sx={{ gap: "8px" }}
            style={{ fontFamily: "Rawline Medium", textTransform: "uppercase" }}
          >
            <SaveIcon fontSize="small" />
            <Typography variant={"BUTTON TEXT"}>Salvar resposta</Typography>
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

FormQueroSerEmpreendedor.propTypes = {
  initialValues: PropTypes.object,
  visualizacao: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default FormQueroSerEmpreendedor;
