import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Card, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

import AprovarOuReprovarCurso from "../modals/AprovarOuReprovarCurso";
import InativarCurso from "../modals/InativarCurso";
import DefaultTable from "../table/DefaultTable";

const tabelaColunas = [
  { field: "nomeCurso", headerName: "Curso" },
  { field: "nomeRepresentante", headerName: "Ofertante" },
];

const keysHidden = ["cnpj", "status", "vigencia", "dataUltimaModificacao"];

// TODO:  A informação da 'Vigência' é calculada automaticamente pelo sistema. Quando o Parceiro insere a 'data de início do curso' e 'data de fim do curso'. Então, o sistema informa no campo da Vigência se  o curso está com 'Inscrições abertas', se o curso está 'Encerrado' ou se foi 'Cancelado'"}

const ParceiroListarCursos = ({ initialData }) => {
  const [inativarCurso, setInativarCurso] = useState(false);
  const [reprovarCurso, setReprovarCurso] = useState(false);
  const navigate = useNavigate();

  // console.log(initialData);
  const tabelaHiddenLinhas = initialData.map(row =>
    keysHidden.reduce((acc, key) => {
      acc[key] = row[key];
      return acc;
    }, {}),
  );

  const getTabelaActions = () => [
    {
      title: "Visualizar",
      href: "",
      icon: "visibility",
      onClick: rowId => navigate("/parceiro/cursos/visualizar/" + rowId),
    },
    {
      title: "Editar",
      href: "",
      icon: "create",
      onClick: rowId => navigate("/parceiro/cursos/editar" + rowId),
    },
    {
      title: "Duplicar",
      href: "",
      icon: "file_copy",
      onClick: rowId => navigate("/parceiro/cursos/duplicar" + rowId),
    },
    {
      title: "Interessados",
      href: "",
      icon: "person",
      onClick: rowId => navigate("/parceiro/cursos/interessados" + rowId),
    },
    {
      title: "Inativar",
      icon: "delete",
      onClick: () => setInativarCurso(true),
    },
  ];

  const fecharInativarCurso = () => {
    setInativarCurso(false);
  };

  const fecharReprovarCurso = () => {
    setReprovarCurso(false);
  };

  return (
    <>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          {/* Tabela */}
          <Box sx={{ flexGrow: 1 }}>
            <DefaultTable
              rows={initialData}
              notFoundText={"Não foi localizado Cursos na situação pendente de aprovação!"}
              columns={tabelaColunas}
              hiddenRows={tabelaHiddenLinhas}
              actionButtons={getTabelaActions}
              termos={{
                cnpj: "CNPJ Ofertante",
                status: "Status",
                vigencia: "Vigência",
                dataUltimaModificacao: "Data da Última Modificação",
              }}
            />
          </Box>
        </Card>
      </Stack>
      {/* Modais */}
      <div>
        <InativarCurso
          showModal={inativarCurso}
          handleClose={fecharInativarCurso}
        />
        <AprovarOuReprovarCurso
          modalTitle="Reoprovar curso"
          showModal={reprovarCurso}
          handleClose={fecharReprovarCurso}
        />
      </div>
    </>
  );
};

ParceiroListarCursos.propTypes = {
  initialData: PropTypes.array,
};

export default ParceiroListarCursos;
