import React, { useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import CircleIcon from "@mui/icons-material/Circle";
import SearchIcon from "@mui/icons-material/Search";
import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  Grid,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import DefaultTable from "../../components/table/DefaultTable";
import adminService from "../../services/admin.service";
import { formatCNPJ, removeFormatCNPJ } from "./utils";

// Retornar a pesquisa vazia
const initialFilterState = {
  cnpj: "",
};

const AdminUsuariosRepresentantesParceiro = ({ listaEmpresas }) => {
  const [filter, setFilter] = useState(initialFilterState);
  const [filteredData, setFilteredData] = useState(null);

  const [idEmpresa, setIdEmpresa] = useState(null);
  const [dados, setDados] = useState([]);
  const [emailUsuario, setEmailUsuario] = useState("");

  const [mensagemNaoEncontrado, setMensagemNaoEncontrado] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleFilterChange = event => {
    const { name, value } = event.target;
    setFilter({ [name]: value });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const applyFilter = async () => {
    setIsLoading(true);
    try {
      let id = null;
      listaEmpresas.forEach(item => {
        if (item.cnpj == removeFormatCNPJ(filter.cnpj)) {
          id = item.id;
          setDados(item);
        }
      });
      if (id) {
        const representantes = await adminService.getRepresentantesEmpresa(id);
        setIdEmpresa(id);
        setFilteredData(representantes);
        setMensagemNaoEncontrado(false);
      } else {
        setFilteredData(null);
        setMensagemNaoEncontrado(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenDialog = user => {
    setSelectedUser(user);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedUser(null);
  };

  const handleConfirmDelete = () => {
    const motivo = {
      motivo: "Exclusão por parte do admin",
      id_empresa: idEmpresa,
    };
    adminService.excluiRepresentanteEmpresa(selectedUser.pk_usuario, motivo);
    applyFilter();
    handleCloseDialog();
  };

  const formatarDataBrasileira = data => {
    return new Date(data).toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          {/* Campos para filtrar */}
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
              sm
            >
              <FormGroup>
                <TextField
                  id="cnpj"
                  name="cnpj"
                  label="CNPJ"
                  placeholder="CNPJ"
                  value={formatCNPJ(filter.cnpj)}
                  type="text"
                  onChange={handleFilterChange}
                  fullWidth
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={"auto"}
            >
              <Button
                variant="contained"
                onClick={applyFilter}
                sx={{ gap: "8px", mt: "8px" }}
                style={{ fontFamily: "Rawline Medium" }}
                fullWidth
              >
                <SearchIcon />
                Buscar
              </Button>
            </Grid>
          </Grid>

          {isLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              my={4}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {mensagemNaoEncontrado && (
                <Typography
                  variant="subtitle2"
                  color="error"
                >
                  Nenhuma empresa encontrada
                </Typography>
              )}

              {filteredData && (
                <>
                  <Grid
                    container
                    my={2}
                  >
                    <Typography variant="subtitle1">Representantes do parceiro</Typography>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormGroup>
                        <TextField
                          disabled
                          id="razaoSocial"
                          name="razaoSocial"
                          label="Razão Social"
                          placeholder="Razão Social"
                          value={dados.razaoSocial}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormGroup>
                        <TextField
                          disabled
                          id="nomefantasia"
                          name="nomefantasia"
                          label="Nome fantasia"
                          placeholder="Nome fantasia"
                          value={dados.nomeFantasia}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormGroup>
                        <TextField
                          disabled
                          id="dataCadastro"
                          name="dataCadastro"
                          label="Data do Cadastro"
                          placeholder="Data do Cadastro"
                          value={formatarDataBrasileira(dados.dataCadastro)}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormGroup>
                        <TextField
                          disabled
                          id="dataUltimaModificacao"
                          name="dataUltimaModificacao"
                          label="Data da Última Modificação"
                          placeholder="Data da Última Modificação"
                          value={formatarDataBrasileira(dados.dataUltimaModificacao)}
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>

                  {/* Cores */}
                  <Box>
                    <Grid
                      container
                      mt={2}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={4}
                      >
                        <CircleIcon sx={{ color: "#FF7A00", fontSize: "small", marginRight: "5px" }} />
                        Usuário com a senha resetada
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                      >
                        <CircleIcon sx={{ color: "#FFCF00", fontSize: "small", marginRight: "5px" }} />
                        Usuário que nunca acessou o parceiro
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                      >
                        <CircleIcon sx={{ color: "#00CA45", fontSize: "small", marginRight: "5px" }} />
                        Usuário que já acessou o parceiro
                      </Grid>
                    </Grid>
                  </Box>

                  {/* Adicionar Representante */}
                  <Grid
                    container
                    mt={2}
                    spacing={1}
                  >
                    <Grid
                      item
                      xs={12}
                      sm
                    >
                      <FormGroup>
                        <TextField
                          id="emailUsuario"
                          name="emailUsuario"
                          label="E-mail do usuário"
                          placeholder="E-mail do usuário"
                          value={emailUsuario}
                          onChange={event => {
                            setEmailUsuario(event.target.value);
                          }}
                          type="text"
                          fullWidth
                        />
                      </FormGroup>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={"auto"}
                    >
                      <Button
                        variant="contained"
                        onClick={() => {
                          adminService.postRepresentanteEmpresa({
                            email_representante: emailUsuario,
                            id_empresa: idEmpresa,
                          });
                          applyFilter();
                        }}
                        sx={{ gap: "8px", mt: "5px" }}
                        fullWidth
                      >
                        <AddCircleIcon />
                        Incluir representante
                      </Button>
                    </Grid>
                  </Grid>

                  {/* Tabela */}
                  <Box sx={{ flexGrow: 1, mt: 2 }}>
                    <DefaultTable
                      columns={[
                        { headerName: "Nome Completo", field: "no_usuario" },
                        { headerName: "E-mail", field: "ds_email" },
                      ]}
                      rows={filteredData}
                      actionButtons={event => [
                        {
                          title: "Remover",
                          icon: "block",
                          onClick: () => {
                            if (filteredData.length <= 1) {
                              setSnackbarMessage("A empresa deve possuir ao menos um representante!");
                              setOpenSnackbar(true);
                            } else {
                              handleOpenDialog(event);
                            }
                          },
                        },
                      ]}
                      mostrarCirculosColoridos={true}
                      listaCirculosColoridos={["#FF7A00", "#FFCF00", "#00CA45"]}
                    />
                  </Box>
                </>
              )}
            </>
          )}
        </Card>
      </Stack>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>Tem certeza que deseja excluir o usuário?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="primary"
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

AdminUsuariosRepresentantesParceiro.propTypes = {
  listaEmpresas: PropTypes.array,
};

export default AdminUsuariosRepresentantesParceiro;
