import React, { useRef, useState } from "react";

import SaveIcon from "@mui/icons-material/Save";
import { Box, Button, Card, Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";

import FormCursosTecnicoComplementar from "./cidadaoEditarCurriculo/FormCursosTecnicoComplementar";
import FormDadosPessoais from "./cidadaoEditarCurriculo/FormDadosPessoais";
import FormEscolaridade from "./cidadaoEditarCurriculo/FormEscolaridade";
import FormExperiencias from "./cidadaoEditarCurriculo/FormExperiencias";
import FormInteresse from "./cidadaoEditarCurriculo/FormInteresse";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CidadaoTabs() {
  const [value, setValue] = useState(0);
  const formInteresseRef = useRef(null);
  const formCursosTecnicoComplementarRef = useRef(null);
  const formDadosPessoaisRef = useRef(null);
  const formEscolaridadeRef = useRef(null);

  const [formData, setFormData] = useState({
    dadosPessoais: {},
    escolaridade: {},
    cursos: {},
    experiencias: {},
    interesse: {},
  });
  // ... existing refs ...

  const handleFormDataChange = (section, data) => {
    setFormData(prevData => ({
      ...prevData,
      [section]: data,
    }));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNext = async () => {
    let isValid = true;

    switch (value) {
      case 0: {
        const dadosPessoaisData = await formDadosPessoaisRef.current?.getFormValues();

        if (isValid) {
          handleFormDataChange("dadosPessoais", dadosPessoaisData);
        }
        console.log("FORM PESSOAIS -> ", dadosPessoaisData);

        break;
      }
      case 1: {
        const escolaridadeData = await formEscolaridadeRef.current?.getFormValues();

        if (isValid) {
          handleFormDataChange("escolaridade", escolaridadeData);
        }
        console.log("FORM ESCOLARIDADE -> ", escolaridadeData);

        break;
      }
      case 2: {
        const cursosData = await formCursosTecnicoComplementarRef.current?.getFormValues();

        if (isValid) {
          handleFormDataChange("cursos", cursosData);
        }
        console.log("FORM CURSOS -> ", cursosData);

        break;
      }
      case 3:
        // Assuming you'll add a ref for FormExperiencias
        // isValid = await formExperienciasRef.current?.validateForm();
        break;
      case 4:
        isValid = await formInteresseRef.current?.validateForm();
        break;
      default:
        console.log("Você está em uma seção desconhecida.");
        return;
    }

    if (isValid) {
      setValue(prevValue => Math.min(prevValue + 1, 4));
    } else {
      console.log("Por favor, corrija os erros antes de prosseguir.");
    }
  };

  const handlePrevious = () => {
    if (value > 0) {
      setValue(prevValue => prevValue - 1);
    }
    console.log("VALORES DO FORM VALUES -> ", formData);
  };

  const handleSave = async () => {
    console.log("FORM COMPLETO -> ", formData);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
          }}
          style={{ transition: "1s" }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
            >
              <Tab
                label="1. Dados pessoais"
                {...a11yProps(0)}
                sx={{
                  color: value === 0 ? "primary.main" : "text.primary",
                  fontFamily: "Rawline Light",
                }}
              />
              <Tab
                label="2. Escolaridade"
                {...a11yProps(1)}
                sx={{
                  color: value === 1 ? "primary.main" : "text.primary",
                  fontFamily: "Rawline Light",
                }}
              />
              <Tab
                label="3. Cursos"
                {...a11yProps(2)}
                sx={{
                  color: value === 2 ? "primary.main" : "text.primary",
                  fontFamily: "Rawline Light",
                }}
              />
              <Tab
                label="Experiência"
                {...a11yProps(3)}
                sx={{
                  color: value === 3 ? "primary.main" : "text.primary",
                  fontFamily: "Rawline Light",
                }}
              />
              <Tab
                label="Interesse"
                {...a11yProps(4)}
                sx={{
                  color: value === 4 ? "primary.main" : "text.primary",
                  fontFamily: "Rawline Light",
                }}
              />
            </Tabs>
          </Box>
          <CustomTabPanel
            value={value}
            index={0}
          >
            <FormDadosPessoais
              ref={formDadosPessoaisRef}
              defaultValues={formData.dadosPessoais.values}
              onSubmit={data => console.log("FormDadosPessoais data submitted:", data)}
            />
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={1}
          >
            <FormEscolaridade
              ref={formEscolaridadeRef}
              defaultValues={formData.escolaridade.values}
              onSubmit={data => console.log("FormEscolaridade data submitted:", data)}
            />
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={2}
          >
            <FormCursosTecnicoComplementar
              ref={formCursosTecnicoComplementarRef}
              defaultValues={formData.cursos.values}
              onSubmit={data => console.log("FormCursosTecnicoComplementar data submitted:", data)}
            />
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={3}
          >
            <FormExperiencias />
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={4}
          >
            <FormInteresse
              // onFormChange={handleFormInteresseChange}
              onSubmit={data => console.log("FormInteresse data submitted:", data)}
              ref={formInteresseRef}
            />
          </CustomTabPanel>
        </Card>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2, gap: "10px 10px" }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handlePrevious}
          disabled={value === 0}
        >
          ANTERIOR
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleNext}
          disabled={value === 4}
        >
          PRÓXIMO
        </Button>
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={handleSave}
        >
          SALVAR
        </Button>
      </Box>
    </>
  );
}
