import React, { forwardRef, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";

import InfoIcon from "@mui/icons-material/Info";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import { tp_estado_civil, tp_sexo } from "../../../services/constants.js";
import CustomDatePicker from "../CustomDatePicker.js";
import { FormFieldsGrid } from "../DynamicForm.js";
import ReusableSwitch from "../fields/ReusableSwitch.js";
import FormAddress from "../FormAddress.js";
import { formatTelefone } from "../utils.js";

const FormDadosPessoais = forwardRef(({ defaultValues }, ref) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({
    defaultValues: defaultValues || {
      email: "",
      nome: "",
      nomePai: "",
      nomeMae: "",
      emailAdicional: "",
      telefone: "",
      estadoCivil: "",
      escolaridade: "",
      cnpj: "",
      corRaca: "",
      sexo: "",
      faixaRenda: "",
      acessoInternet: "",
      possuiCnh: false,
      interesseTrabalharOutraLocalidade: false,
      pcd: false,
      toggleCienteGratuito: false,
    },
    mode: "onBlur",
  });

  const watchPossuiCnh = watch("possuiCnh");

  const handleFormSubmit = data => {
    const formData = {
      ...data,
    };
    console.log("Form data:", formData);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      console.log("SUBMIT FORM DADOS PESSOAIS");
      handleSubmit(handleFormSubmit)();
    },
    getFormValues: async () => {
      // Trigger validation for all fields
      const isValid = await trigger();
      const values = getValues();
      const currentErrors = errors;

      return {
        values: { ...values },
        errors: isValid ? null : currentErrors,
      };
    },
  }));

  const fields = [
    {
      name: "nome",
      label: "Nome para currículo",
      validation: { required: "Nome é obrigatório" },
      renderComponent: ({ value, onChange, error }) => (
        <TextField
          value={value}
          onChange={onChange}
          label="Nome para currículo"
          placeholder="Insira o seu Nome completo"
          fullWidth
          error={!!error}
          helperText={error?.message}
        />
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "email",
      label: "E-mail",
      validation: {
        required: "E-mail é obrigatório",
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: "E-mail inválido",
        },
      },
      renderComponent: ({ value, onChange, error }) => (
        <TextField
          value={value}
          onChange={onChange}
          label="* E-mail"
          placeholder="Insira seu E-mail aqui"
          fullWidth
          error={!!error}
          helperText={error?.message}
        />
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "dataNascimento",
      label: "Data de nascimento",
      validation: { required: "Data de nascimento é obrigatória" },
      renderComponent: ({ value, onChange, error }) => (
        <CustomDatePicker
          value={value}
          onChange={onChange}
          label="* Data de nascimento"
          id="dataResposta"
          name="dataResposta"
          format="DD/MM/YYYY"
          slotProps={{
            textField: {
              fullWidth: true,
              error: !!error,
              helperText: error?.message,
            },
          }}
        />
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "estadoCivil",
      label: "Estado Civil",
      validation: { required: "Estado Civil é obrigatório" },
      renderComponent: ({ value, onChange, error }) => (
        <FormControl
          fullWidth
          error={!!error}
        >
          <InputLabel id="estadoCivilLabel">* Estado Civil</InputLabel>
          <Select
            value={value}
            onChange={onChange}
            labelId="estadoCivilLabel"
            label="Estado Civil"
          >
            <MenuItem
              value=""
              disabled
            >
              Selecione o Estado Civil
            </MenuItem>
            {Object.entries(tp_estado_civil).map(([value, label]) => (
              <MenuItem
                key={value}
                value={value}
              >
                {label}
              </MenuItem>
            ))}
          </Select>
          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "sexo",
      label: "Gênero",
      renderComponent: ({ value, onChange }) => (
        <FormControl fullWidth>
          <InputLabel id="generoLabel">Gênero</InputLabel>
          <Select
            value={value}
            onChange={onChange}
            labelId="generoLabel"
            label="Gênero"
          >
            <MenuItem
              value=""
              disabled
            >
              Selecione o Sexo
            </MenuItem>
            {Object.entries(tp_sexo).map(([value, label]) => (
              <MenuItem
                key={value}
                value={value}
              >
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "telefone",
      label: "Telefone",
      validation: { required: "Telefone é obrigatório" },
      renderComponent: ({ value, onChange, error }) => (
        <TextField
          value={value}
          label="* Telefone"
          placeholder="Insira o Telefone"
          fullWidth
          onChange={e => onChange(formatTelefone(e.target.value))}
          inputProps={{ maxLength: 15 }}
          error={!!error}
          helperText={error?.message}
        />
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "telefone_adicional",
      label: "Telefone adicional",
      renderComponent: ({ value, onChange }) => (
        <TextField
          value={value}
          onChange={onChange}
          label="Telefone adicional"
          placeholder="Insira o Telefone"
          fullWidth
          inputProps={{ maxLength: 15 }}
        />
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "possuiCnh",
      label: "Possui CNH?",
      renderComponent: ({ value }) => (
        <>
          <Typography style={{ fontFamily: "rawline Bold", marginBottom: "5px" }}>Possui CNH?</Typography>
          <ReusableSwitch
            {...value}
            checked={value}
          />
        </>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "tipo_cnh",
      label: "Tipo de CNH",
      renderComponent: ({ value, onChange }) => (
        <TextField
          value={value}
          onChange={onChange}
          label="Tipo de CNH"
          placeholder="Insira o tipo da sua CNH"
          fullWidth
          disabled={!watchPossuiCnh}
        />
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "endereco",
      label: "Endereço",
      renderComponent: ({ value, onChange }) => (
        <FormAddress
          {...value}
          onChange={onChange}
        />
      ),
      size: { xs: 12, sm: 12 },
    },
    {
      name: "pcd",
      label: "PCD",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography style={{ fontFamily: "rawline Bold", marginBottom: "5px", display: "flex", alignItems: "center", gap: "5px" }}>
            PCD
            <Tooltip
              title="Pessoa com Deficiência"
              placement="right"
            >
              <InfoIcon
                color="primary"
                sx={{ width: "15px", height: "15px" }}
              />
            </Tooltip>
          </Typography>
          <ReusableSwitch
            checked={value}
            onChange={onChange}
          />
        </>
      ),
      size: { xs: 12, sm: 12 },
    },
    {
      name: "interesseTrabalharOutraLocalidade",
      label: "Interesse em trabalhar em outra localidade",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography style={{ fontFamily: "rawline Bold", marginBottom: "5px" }}>
            Interesse em trabalhar em outro município ou região
          </Typography>
          <ReusableSwitch
            checked={value}
            onChange={onChange}
          />
        </>
      ),
      size: { xs: 12, sm: 12 },
    },
    {
      name: "toggleCienteGratuito",
      label: "Ciente de gratuidade",
      renderComponent: ({ value }) => (
        <FormControlLabel
          control={
            <Checkbox
              {...value}
              checked={value}
              style={{ color: "grey" }}
            />
          }
          label={
            <Typography
              variant="body1"
              style={{ fontFamily: "Rawline Light" }}
            >
              Aceito receber, por telefone e/ou e-mail, informações sobre vagas de emprego, cursos de qualificação profissional, e autorizo
              que os dados do currículo sejam divulgados para empresar cadastradas com forma de buscar uma oportunidade no mercado de
              trabalho. E estou ciente que o Plano Acredita no Primeiro Passo. não se responsabiliza pela contratação de emprego, estágio ou
              jovem aprendiz em nenhuma fase.
            </Typography>
          }
        />
      ),
      size: { xs: 12, sm: 12 },
    },
  ];

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <FormFieldsGrid
        fields={fields}
        control={control}
        errors={errors}
      />
    </form>
  );
});

FormDadosPessoais.displayName = "FormDadosPessoais";

FormDadosPessoais.propTypes = {
  defaultValues: PropTypes.shape({
    email: PropTypes.string,
    nome: PropTypes.string,
    nomePai: PropTypes.string,
    nomeMae: PropTypes.string,
    emailAdicional: PropTypes.string,
    telefone: PropTypes.string,
    estadoCivil: PropTypes.string,
    escolaridade: PropTypes.string,
    cnpj: PropTypes.string,
    uf: PropTypes.string,
    municipio: PropTypes.string,
    corRaca: PropTypes.string,
    sexo: PropTypes.string,
    faixaRenda: PropTypes.string,
    acessoInternet: PropTypes.string,
    possuiCnh: PropTypes.bool,
    interesseTrabalharOutraLocalidade: PropTypes.bool,
    pcd: PropTypes.bool,
    toggleCienteGratuito: PropTypes.bool,
    endereco: PropTypes.shape({
      cep: PropTypes.string,
      endereco: PropTypes.string,
      numero: PropTypes.string,
      complemento: PropTypes.string,
      bairro: PropTypes.string,
      uf: PropTypes.string,
      municipio: PropTypes.string,
    }),
  }),
  onSubmit: PropTypes.func.isRequired,
};

export default FormDadosPessoais;
