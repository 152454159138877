import React from "react";

import { CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

import { useResponsive } from "../../hooks/use-responsive";

const LoadingComponent = ({ isLoading, fullScreen, widescreen }) => {
  const theme = useTheme();

  //Adicionando margin dos components de breadcrumb
  const H_MOBILE = 64;
  const F_MOBILE = 60;
  const MARGIN_MOBILE = H_MOBILE + F_MOBILE + 32;

  const H_DESKTOP = 80;
  const F_DESKTOP = 40;
  const MARGIN_DESKTOP = H_DESKTOP + F_DESKTOP + 64;

  const smUp = useResponsive("up", "sm");

  return (
    //Caso seja widescreen
    (isLoading && widescreen && (
      <div
        style={{
          zIndex: "3",
          width: "100%",
          height: smUp ? "calc(100vh - " + MARGIN_DESKTOP + "px)" : "calc(100vh - " + MARGIN_MOBILE + "px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          top: "0px",
          right: "0px",
        }}
      >
        <CircularProgress size={80} />
        <a style={{ color: theme.palette.primary.main, fontFamily: "Rawline Bold", fontSize: "24px" }}>Carregando...</a>
      </div>
    )) ||
    //Caso seja fullscreen
    (isLoading && fullScreen && (
      <div
        style={{
          zIndex: "3",
          width: "100vw",
          height: "100vh",
          backgroundColor: theme.palette.text.inverter,
          opacity: "0.8",
          position: "fixed",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          top: "0px",
          right: "0px",
        }}
      >
        <CircularProgress size={80} />
        <a style={{ color: theme.palette.primary.main, fontFamily: "Rawline Bold", fontSize: "24px" }}>Carregando...</a>
      </div>
    )) ||
    //Caso NAO seja fullScreen
    (isLoading && !fullScreen && (
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress size={80} />
        <a style={{ color: theme.palette.primary.main, fontFamily: "Rawline Bold", fontSize: "24px" }}>Carregando...</a>
      </div>
    ))
  );
};

LoadingComponent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  widescreen: PropTypes.bool,
};

export default LoadingComponent;
