import React, { useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { Button, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import CustomDatePicker from "../formularios/CustomDatePicker";
import { tabelaCursosPendentes } from "../formularios/dadosMockados";
import AprovarOuReprovarCurso from "../modals/AprovarOuReprovarCurso";
import InativarCurso from "../modals/InativarCurso";
import DefaultTable from "../table/DefaultTable";

// Dados fictícios para teste da tabela
const initialData = tabelaCursosPendentes;

// Retornar a pesquisa vazia
const initialFilterState = {
  dataDoCadastroInicio: null,
  dataDoCadastroFim: null,
  dataDaUltimaModificacaoInicio: null,
  dataDaUltimaModificacaoFim: null,
};

const tabelaColunas = [
  { field: "nome", headerName: "Curso" },
  { field: "ofertante", headerName: "Ofertante" },
];

const keysHidden = ["cnpj", "dataCadastro", "dataUltimaModificacao", "modalidade", "vagas"];

const AdminListarCursosAprovados = () => {
  const [filter, setFilter] = useState(initialFilterState);
  const [filteredData, setFilteredData] = useState(initialData);
  const [inativarCurso, setInativarCurso] = useState(false);
  const [reprovarCurso, setReprovarCurso] = useState(false);

  const tabelaHiddenLinhas = filteredData.map(row =>
    keysHidden.reduce((acc, key) => {
      acc[key] = row[key];
      return acc;
    }, {}),
  );

  const applyFilter = () => {
    const filtered = initialData.filter(parceiro => {
      return (
        (!filter.dataDoCadastroInicio ||
          !filter.dataDoCadastroFim ||
          dayjs(parceiro.dataCadastro).isBetween(filter.dataDoCadastroInicio, filter.dataDoCadastroFim, "day", "[]")) &&
        (!filter.dataDaUltimaModificacaoInicio ||
          !filter.dataDaUltimaModificacaoFim ||
          dayjs(parceiro.dataUltimaModificacao).isBetween(
            filter.dataDaUltimaModificacaoInicio,
            filter.dataDaUltimaModificacaoFim,
            "day",
            "[]",
          ))
      );
    });
    setFilteredData(filtered);
  };

  const resetFilter = () => {
    setFilter(initialFilterState);
    applyFilter();
  };

  const getTabelaActions = () => [
    {
      title: "Visualizar",
      href: "",
      icon: "visibility",
    },
    {
      title: "Reprovar",
      icon: "thumb_down",
      onClick: () => setReprovarCurso(true),
    },
    {
      title: "Interessados",
      href: "",
      icon: "person",
    },
    {
      title: "Duplicar Curso",
      href: "",
      icon: "file_copy",
    },
    {
      title: "Visualizar Anexos",
      href: "/listar-anexos-curso",
      icon: "attach_file",
      onClick: rowId => {
        localStorage.setItem("cursosID", JSON.stringify(rowId));
        localStorage.setItem("linkRetorno", JSON.stringify("/listar-cursos-aprovados"));
      },
    },
    {
      title: "Inativar",
      icon: "cancel",
      onClick: () => setInativarCurso(true),
    },
  ];

  const fecharInativarCurso = () => {
    setInativarCurso(false);
  };

  const fecharReprovarCurso = () => {
    setReprovarCurso(false);
  };

  return (
    <>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          {/* Campos para filtrar */}
          <Grid
            spacing={3}
            container
          >
            <Grid
              item
              xs={12}
            >
              <Typography variant="subtitle2">Filtros</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Card style={{ border: "1px solid #d3d3d3" }}>
                <CardContent>
                  <p style={{ fontFamily: "Rawline Regular", color: "grey" }}>Data do Cadastro</p>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={6}
                      sm={6}
                    >
                      <CustomDatePicker
                        label="Início"
                        id="dataDoCadastroInicio"
                        name="dataDoCadastroInicio"
                        value={filter.dataDoCadastroInicio}
                        format="DD/MM/YYYY"
                        onChange={valor => setFilter({ ...filter, ["dataDoCadastroInicio"]: valor })}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                    >
                      <CustomDatePicker
                        id="dataDoCadastroFim"
                        name="dataDoCadastroFim"
                        value={filter.dataDoCadastroFim}
                        label="Fim"
                        format="DD/MM/YYYY"
                        onChange={valor => setFilter({ ...filter, ["dataDoCadastroFim"]: valor })}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Card style={{ border: "1px solid #d3d3d3" }}>
                <CardContent>
                  <p style={{ fontFamily: "Rawline Regular", color: "grey" }}>Data da última modificação</p>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={6}
                      sm={6}
                    >
                      <CustomDatePicker
                        id="dataDaUltimaModificacaoInicio"
                        name="dataDaUltimaModificacaoInicio"
                        value={filter.dataDaUltimaModificacaoInicio}
                        label="Início"
                        format="DD/MM/YYYY"
                        onChange={valor => setFilter({ ...filter, ["dataDaUltimaModificacaoInicio"]: valor })}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                    >
                      <CustomDatePicker
                        id="dataDaUltimaModificacaoFim"
                        name="dataDaUltimaModificacaoFim"
                        value={filter.dataDaUltimaModificacaoFim}
                        label="Fim"
                        format="DD/MM/YYYY"
                        onChange={valor => setFilter({ ...filter, ["dataDaUltimaModificacaoFim"]: valor })}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Botões de pesquisa/cancelar */}
          <Box sx={{ flexGrow: 1, mt: "16px" }}>
            <Grid
              container
              spacing={1}
              justifyContent="flex-end"
            >
              <Grid
                item
                xs={12}
                sm={"auto"}
              >
                <Button
                  variant="outlined"
                  onClick={resetFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                  fullWidth
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={"auto"}
              >
                <Button
                  variant="contained"
                  onClick={applyFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                  fullWidth
                >
                  <SearchIcon />
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
          </Box>

          {/* Tabela */}
          <Box sx={{ flexGrow: 1, mt: "40px" }}>
            <DefaultTable
              rows={filteredData}
              notFoundText={"Não foi localizado Cursos na situação aprovado!"}
              columns={tabelaColunas}
              hiddenRows={tabelaHiddenLinhas}
              actionButtons={getTabelaActions}
              termos={{
                cnpj: "CNPJ Ofertante",
                dataCadastro: "Data do Cadastro",
                dataUltimaModificacao: "Data da última modificação",
                modalidade: "Modalidade",
                vagas: "Vagas",
              }}
            ></DefaultTable>
          </Box>
        </Card>
      </Stack>
      {/* Modais */}
      <div>
        <InativarCurso
          showModal={inativarCurso}
          handleClose={fecharInativarCurso}
        />
        <AprovarOuReprovarCurso
          modalTitle="Reprovar curso"
          showModal={reprovarCurso}
          handleClose={fecharReprovarCurso}
        />
      </div>
    </>
  );
};

AdminListarCursosAprovados.propTypes = {
  setServicosModal: PropTypes.func,
  servicosModal: PropTypes.bool,
};

export default AdminListarCursosAprovados;
