import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Container } from "@mui/material";
import { useQueries } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import FormCurso from "../../components/formularios/FormCurso";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import parceiroService from "../../services/parceiro.service";

const PageNovoCurso = () => {
  const navigate = useNavigate();

  const results = useQueries({
    queries: [
      {
        queryKey: ["empresasParceiras"],
        queryFn: parceiroService.getEmpresasParceiras,
      },
      {
        queryKey: ["trilhas"],
        queryFn: parceiroService.getTrilhasCursos,
      },
    ],
  });

  // Desestruturação dos resultados das queries
  const [empresasQuery, trilhasQuery] = results;
  const [isLoading, setIsLoading] = useState(false);

  // Dados carregados com sucesso
  // Dados carregados com sucesso
  const empresas = empresasQuery.data;
  const trilhas = trilhasQuery.data;

  // Variaveis de estado
  const isError = empresasQuery.isError || trilhasQuery.isError;
  const isPending = empresasQuery.isPending || trilhasQuery.isPending;

  const handleSubmit = async values => {
    console.log("handle submit na page: ", values);
    setIsLoading(true);
    let files = values.anexo;

    try {
      if (files?.length > 0) {
        // Aceita um único arquivo
        const arquivo = {
          nome_anexo: files[0].name.split(".")[0],
          extensao_anexo: files[0].name.split(".")[1],
          file: files[0].file,
        };

        // console.log("Arquivos: ", arquivos);
        parceiroService
          .postInsereArquivo(arquivo)
          .then(resposta => {
            console.log("Sucesso ao inserir arquivo:", resposta.message);

            const cursoPayload = {
              dados: {
                fk_anexos_foto: resposta.id,
                fk_empresa_parceira: values.cnpjOfertante, //recebo o id da empresa
                carga_horaria: values.cargaHoraria,
                nome_curso: values.nomeDoCurso,
                st_pronatec: values.pronatec,
                dt_inicio_matricula: values.dataMatriculaInicio,
                dt_inicio: values.dataCursoInicio,
                dt_fim: values.dataCursoFim,
                ementa: values.ementa,
                breve_descricao: values.descricao,
                tipo_curso: values.tipoDeCurso,
                tipo_modalidade: values.modalidade,
                tipo_escolaridade: values.escolaridade,
                tipo_turno: values.turno,
                trilhas_curso: [1],

                // Campos opcionais
                ...(values.preRequisitos.length > 0 && { fk_curso_pre_requisito: values.preRequisitos }),
                ...(values.dataMatriculaFim && { dt_fim_matricula: values.dataMatriculaFim }),
                ...(values.idadeMinima && { idade_minima: values.idadeMinima }),
                ...(values.idadeMaxima && { idade_maxima: values.idadeMaxima }),
                ...(values.link && { link_externo: values.link }),
              },
              // Detalhes tem que ser enviado vazio se nao tiver
              detalhes: values.FormAddressSchool
                ? values.FormAddressSchool.map(valor => ({
                    endereco: {
                      cep: valor.cep.replace(/[.-]/g, ""),
                      numero: valor.numero || "0",
                      complemento: valor.complemento || "",
                      bairro: valor.bairro,
                      logradouro: valor.endereco,
                      uf: valor.uf,
                      cidade: valor.municipio,
                      telefone: valor.telefone,
                    },
                    dt_curso_inicio: valor.dataInicioCurso,
                    dt_curso_fim: valor.dataFinalCurso,
                    qt_vagas: valor.vagas,
                    tp_abrangencia: valor.abrangencia,
                  }))
                : [],
            };

            console.log(cursoPayload);

            parceiroService
              .postCadastrarCurso(cursoPayload)
              .then(resposta => {
                console.log("Sucesso ao cadastrar curso: ", resposta);
                navigate("/parceiro/cursos/listar");
              })
              .catch(erro => {
                // TODO implementar delete de arquivo
                console.error("Erro ao cadastrar curso", erro);
              });
          })
          .catch(erro => {
            console.error("Erro ao inserir arquivo", erro);
          });
      } else {
        console.log("Insira um arquivo");
      }
    } catch (erro) {
      console.error("Erro ao inserir curso", erro);
    } finally {
      setIsLoading(false);
    }
  };

  if (isError) {
    console.error("Erro de carregamento");
  }

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/cursos", text: "Cursos" }]}
        currentPage={"Novo"}
      />

      <TitlePage title={"Novo curso"} />

      {isPending || isLoading ? (
        <LoadingComponent
          isLoading={isPending || isLoading}
          fullScreen={false}
          widescreen={true}
        />
      ) : (
        <FormCurso
          listaEmpresas={empresas}
          listaTrilhas={trilhas}
          onSubmit={handleSubmit}
        />
      )}

      {isError && <ErroCarregamentoModal isShow={true} />}
    </Container>
  );
};

export default PageNovoCurso;
