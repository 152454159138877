import React, { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { Button, FormGroup, Grid, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";

import optionsService from "../../services/options.service.js";
import { SelectCidade } from "./fields/SelectCidade.js";
import { SelectUF } from "./fields/SelectUF.js";
import { formatCEP } from "./utils.js";

let defaultValues = {
  cep: "",
  endereco: "",
  numero: "",
  complemento: "",
  bairro: "",
  uf: "",
  municipio: "",
};

const FormAddress = ({ onChange, values = [defaultValues], allowMultiple = false }) => {
  const [formValues, setFormValues] = useState(values);
  const [ufs, setUfs] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [selectedUf, setSelectedUf] = useState([]);
  const [selectedMunicipio, setSelectedMunicipio] = useState([]);

  useEffect(() => {
    const fetchUfs = async () => {
      try {
        const ufsData = await optionsService.getAllUFs();
        setUfs(ufsData);
      } catch (error) {
        console.error("Erro ao obter UFs:", error);
      }
    };
    fetchUfs();
  }, []);

  const fetchCidadesByUf = async (ufSigla, setCidadesFunction) => {
    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidadesFunction(cidadesData);
    } catch (error) {
      console.error("Erro ao obter cidades:", error);
    }
  };

  const handleSelectUf = (index, event) => {
    const { name, value } = event.target;

    setFormValues(prevFormValues => {
      const newFormValues = [...prevFormValues];
      newFormValues[index] = {
        ...newFormValues[index],
        [name]: value,
        municipio: "",
      };
      onChange(newFormValues);
      return newFormValues;
    });

    setSelectedUf(prevSelectedUf => {
      const updatedSelectedUf = [...prevSelectedUf];
      updatedSelectedUf[index] = value;
      return updatedSelectedUf;
    });

    fetchCidadesByUf(value, cidades => {
      setMunicipios(prevMunicipios => {
        const updatedMunicipios = [...prevMunicipios];
        updatedMunicipios[index] = cidades;
        return updatedMunicipios;
      });
    });
  };

  const handleSelectMunicipio = (index, event) => {
    const { name, value } = event.target;
    setFormValues(prevFormValues => {
      const newFormValues = [...prevFormValues];
      newFormValues[index] = {
        ...newFormValues[index],
        [name]: value,
      };
      // console.log(`nes form - `, newFormValues);
      onChange(newFormValues);
      return newFormValues;
    });

    setSelectedMunicipio(prevSelectedMunicipio => {
      const updatedSelectedMunicipio = [...prevSelectedMunicipio];
      updatedSelectedMunicipio[index] = value;
      return updatedSelectedMunicipio;
    });

    // console.log("updatedSelectedMunicipio - ", selectedMunicipio);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const newFormValues = [...formValues];
    newFormValues[index][name] = value;

    setFormValues(newFormValues);
    // console.log("NEW FORM -> ", newFormValues);
    onChange(newFormValues);
  };

  const handleChangeCEP = async (index, event) => {
    const { value } = event.target;
    const newFormValues = [...formValues];
    newFormValues[index].cep = value;

    setFormValues(newFormValues);

    if (value.replace(/\D/g, "").length === 8) {
      try {
        const dados = await optionsService.verificarCEP(value);
        // console.log("DADOS DO CEP -> ", dados);
        if (dados) {
          newFormValues[index] = {
            ...newFormValues[index],
            uf: dados.uf,
            endereco: dados.logradouro,
            bairro: dados.bairro,
            complemento: dados.complemento || "",
            municipio: dados.localidade,
          };
          const updatedSelectedUf = [...selectedUf];
          updatedSelectedUf[index] = dados.uf;
          const updatedSelectedMunicipio = [...selectedMunicipio];
          updatedSelectedMunicipio[index] = dados.localidade;
          setSelectedUf(updatedSelectedUf);
          setSelectedMunicipio(updatedSelectedMunicipio);
          setFormValues(newFormValues);
          onChange(newFormValues);
          fetchCidadesByUf(dados.uf, cidades => {
            const updatedMunicipios = [...municipios];
            updatedMunicipios[index] = cidades;
            setMunicipios(updatedMunicipios);
          });
        }
      } catch (error) {
        console.error("Erro obtendo CEP", error);
      }
    }
  };

  const addNewAddressForm = () => {
    if (allowMultiple) {
      setFormValues([
        ...formValues,
        {
          cep: "",
          endereco: "",
          numero: "",
          complemento: "",
          bairro: "",
          uf: "",
          municipio: "",
        },
      ]);
    }
  };

  const removeAddressForm = index => {
    if (allowMultiple && formValues.length > 1) {
      const newFormValues = formValues.filter((_, i) => i !== index);
      setFormValues(newFormValues);
      onChange(newFormValues);
    }
  };

  return (
    <>
      {formValues.map((address, index) => (
        <Grid
          container
          spacing={2}
          key={index}
        >
          {allowMultiple && (
            <Grid
              item
              xs={12}
              mb={-2}
            >
              <Typography
                variant="h5"
                sx={{ fontSize: "12", mb: "16px", fontFamily: "Rawline Regular", color: "grey" }}
              >
                Endereço {index + 1}
              </Typography>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={3}
          >
            <FormGroup>
              <TextField
                label="CEP"
                id={`cep-${index}`}
                name="cep"
                value={formatCEP(address.cep)}
                onChange={e => handleChangeCEP(index, e)}
                inputProps={{ maxLength: 10 }}
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormGroup>
              <TextField
                label="Endereço"
                id={`endereco-${index}`}
                name="endereco"
                value={address.endereco}
                onChange={e => handleChange(index, e)}
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
          >
            <FormGroup>
              <TextField
                label="Número"
                id={`numero-${index}`}
                name="numero"
                type="number"
                value={address.numero}
                onChange={e => handleChange(index, e)}
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormGroup>
              <TextField
                label="Complemento"
                id={`complemento-${index}`}
                name="complemento"
                value={address.complemento}
                onChange={e => handleChange(index, e)}
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormGroup>
              <TextField
                label="Bairro"
                id={`bairro-${index}`}
                name="bairro"
                value={address.bairro}
                onChange={e => handleChange(index, e)}
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormGroup>
              <SelectUF
                idSelect={`uf-${index}`}
                nameSelect="uf"
                value={address.uf}
                ufs={ufs}
                uf={selectedUf[index]}
                label="* UF"
                handleSelectUf={e => handleSelectUf(index, e)}
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormGroup>
              <SelectCidade
                idSelect={`municipio-${index}`}
                nameSelect="municipio"
                value={address.municipio}
                cidades={municipios[index] || []}
                cidade={selectedMunicipio[index]}
                label="* Município"
                handleSelectCidade={e => handleSelectMunicipio(index, e)}
              />
            </FormGroup>
          </Grid>

          {allowMultiple && (
            <Grid
              item
              xs={2}
              sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginBottom: "16px" }}
            >
              <Button
                aria-label="delete"
                variant="outlined"
                onClick={() => removeAddressForm(index)}
                color="primary"
                disabled={index === 0 || formValues.length === 1}
              >
                <DeleteIcon />
              </Button>
            </Grid>
          )}
        </Grid>
      ))}
      {allowMultiple && (
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={addNewAddressForm}
        >
          Adicionar Novo Endereço
        </Button>
      )}
    </>
  );
};

FormAddress.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array,
  allowMultiple: PropTypes.bool,
};

export default FormAddress;
