import React from "react";

import { Container } from "@mui/material";

import WarningBox from "../../components/boxes/WarningBox";
import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminInativarEditarParceiro from "../../components/formularios/AdminInativarEditarParceiro";
import TitlePage from "../../components/title-page/TitlePage";

const PageEditarParceiro = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/parceiro/listar/aprovados", text: "Listar Parceiros" }]}
        currentPage="Edição"
      />

      <TitlePage title={"Edição"} />

      <WarningBox />

      <AdminInativarEditarParceiro
        inativar={false}
        saveString="SALVAR"
      />
    </Container>
  );
};

export default PageEditarParceiro;
