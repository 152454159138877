import React, { forwardRef, useImperativeHandle } from "react";
import { Controller, useForm } from "react-hook-form";

import { FormGroup, Grid, TextField } from "@mui/material";
import PropTypes from "prop-types";

const DynamicForm = forwardRef(({ fields, onSubmit, onChange, initialValues = {} }, ref) => {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: initialValues });

  // Expose the functions to the parent component
  useImperativeHandle(ref, () => ({
    submitForm: () => {
      // console.log("Errors -> ", errors);

      handleSubmit(onSubmit)();
    },
    setValue: (name, value) => {
      setValue(name, value);
    },
    getValues: () => getValues(),
  }));

  return (
    <form
      ref={ref}
      onSubmit={handleSubmit(onSubmit)}
      onChange={onChange}
    >
      <FormFieldsGrid
        fields={fields}
        control={control}
        errors={errors}
      />
    </form>
  );
});

DynamicForm.displayName = "DynamicForm";

DynamicForm.propTypes = {
  fields: PropTypes.array.isRequired,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  initialValues: PropTypes.object,
};

export default DynamicForm;

export const FormFieldsGrid = ({ fields, control, errors }) => (
  <Grid
    container
    spacing={2}
  >
    {fields.map(
      ({ name, label = "", placeholder = "", disabled = false, hidden = false, validation, size = {}, renderComponent }, index) => {
        const { xs = 12, sm, md, lg } = size;
        return (
          <Grid
            item
            xs={xs}
            sm={sm !== undefined ? sm : xs}
            md={md !== undefined ? md : sm}
            lg={lg !== undefined ? lg : md}
            key={index + "_" + name}
            style={hidden ? { display: "none" } : {}}
          >
            <BaseFieldController
              name={name}
              control={control}
              validation={validation}
              renderComponent={renderComponent}
              label={label}
              placeholder={placeholder}
              disabled={disabled}
              errors={errors}
            />
          </Grid>
        );
      },
    )}
  </Grid>
);

FormFieldsGrid.propTypes = {
  fields: PropTypes.array.isRequired,
  control: PropTypes.object,
  errors: PropTypes.object,
};

export const FormFieldsNoGrid = ({ fields, control, errors }) => (
  <Grid
    container
    spacing={2}
  >
    <Grid item>
      {fields.map(({ name, label = "", placeholder = "", disabled = false, validation, renderComponent }, index) => (
        <BaseFieldController
          key={index + "_" + name}
          name={name}
          control={control}
          validation={validation}
          renderComponent={renderComponent}
          label={label}
          placeholder={placeholder}
          disabled={disabled}
          errors={errors}
        />
      ))}
    </Grid>
  </Grid>
);

FormFieldsNoGrid.propTypes = {
  fields: PropTypes.array.isRequired,
  control: PropTypes.object,
  errors: PropTypes.object,
};

const BaseFieldController = ({ name, control, validation, renderComponent, label, placeholder, disabled, errors }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={validation}
      render={({ field: { onChange, onBlur, value, ref, touched } }) =>
        renderComponent ? (
          renderComponent({ value, onChange, onBlur, ref, error: errors[name], touched })
        ) : (
          <FormGroup>
            <TextField
              inputRef={ref}
              label={label}
              id={name}
              name={name}
              value={value || ""}
              placeholder={placeholder || ""}
              type="text"
              onChange={onChange}
              onBlur={onBlur}
              disabled={disabled}
              error={!!errors[name]}
              helperText={errors[name]?.message}
            />
          </FormGroup>
        )
      }
    />
  );
};

BaseFieldController.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  control: PropTypes.object,
  renderComponent: PropTypes.func,
  placeholder: PropTypes.string,
  size: PropTypes.shape({
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
  }),
  validation: PropTypes.object,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
};
