import React, { useRef, useState } from "react";

import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";

import TransferList from "../transferList/TransferList";
import DynamicForm from "./DynamicForm";
import { formatCPF, formatTelefone } from "./utils.js";

const AdminUsuario = ({ editar, visualizar, valores }) => {
  const formRef = useRef();

  const [itemsTransferfList, setItemsTransferList] = useState({
    left: ["perfil 1", "perfil 2", "perfil 5"],
    right: ["perfil 3", "perfil 4", "perfil 6"],
  });

  const handleItemsTransferListChange = updatedItems => {
    setItemsTransferList(updatedItems);
  };

  const handleButtonClick = () => {
    if (formRef.current) {
      // formRef.current.submitForm();
      console.log(formRef.current.getValues());
    }
  };

  const fields = [
    {
      name: "nome",
      label: "Nome",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="nome"
          name="nome"
          label="Nome"
          placeholder="Nome"
          value={value}
          onChange={onChange}
          disabled={visualizar}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 4.5 },
    },
    {
      name: "login",
      label: "Login",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="login"
          name="login"
          label="Login"
          placeholder="Login"
          value={value}
          onChange={onChange}
          disabled={visualizar}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 4.5 },
    },
    {
      name: "cpf",
      label: "cpf",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="cpf"
          name="cpf"
          label="CPF"
          placeholder="CPF"
          value={formatCPF(value)}
          onChange={onChange}
          disabled={visualizar}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 4.5, md: 3 },
    },
    {
      name: "tipo",
      label: "Tipo de usuário",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="tipo"
          name="tipo"
          label="Tipo de usuário"
          placeholder="Tipo de usuário"
          value={value}
          onChange={onChange}
          disabled={visualizar}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 4.5, md: 4 },
    },
    {
      name: "email",
      label: "E-mail",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="email"
          name="email"
          label="E-mail"
          placeholder="E-mail"
          value={value}
          onChange={onChange}
          disabled={visualizar}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 4.5, md: 4 },
    },
    {
      name: "telefone",
      label: "Telefone",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="telefone"
          name="telefone"
          label="Telefone"
          placeholder="Telefone"
          value={formatTelefone(value) || ""}
          onChange={onChange}
          disabled={visualizar}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 4.5, md: 4 },
    },
    {
      name: "situacao",
      label: "Situação",
      renderComponent: ({ value, onChange }) => (
        <>
          {(editar || visualizar) && (
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="label-situacao">Situação</InputLabel>
              <Select
                disabled={visualizar}
                id="situacao"
                name="situacao"
                value={value || "none"}
                onChange={onChange}
                labelId="label-situacao"
              >
                <MenuItem
                  value={"none"}
                  disabled
                >
                  Situação
                </MenuItem>
                <MenuItem value={"Ativo"}>Ativo</MenuItem>
                <MenuItem value={"Inativo"}>Inativo</MenuItem>
              </Select>
            </FormControl>
          )}
        </>
      ),
      size: { xs: 12, sm: visualizar ? 6 : 12 },
    },
    {
      name: "perfilDisponivel",
      label: "Perfil Disponível",
      renderComponent: () => {
        <>
          {!visualizar && (
            <TransferList
              items={itemsTransferfList}
              titleLeft={"Perfil Disponível"}
              titleRight={"Perfil selecionado para o usuário"}
              onItemsChange={handleItemsTransferListChange}
            />
          )}
        </>;
      },
      size: { xs: visualizar ? 0.1 : 11.5 },
    },
    {
      name: "perfil",
      label: "Perfil",
      renderComponent: ({ value, onChange }) => (
        <>
          {visualizar && (
            <TextField
              fullWidth
              disabled
              id="perfil"
              name="perfil"
              value={value}
              label="Perfil do Usuário"
              placeholder="Perfil do Usuário"
              onChange={onChange}
            />
          )}
        </>
      ),
      size: { xs: !visualizar ? 0.1 : 12, sm: !visualizar ? 0.1 : 5.75 },
    },
    {
      name: "motivo",
      label: "Motivo",
      renderComponent: ({ value, onChange }) => (
        <>
          {visualizar && (
            <TextField
              fullWidth
              multiline
              disabled={!editar}
              rows={3}
              id="motivo"
              name="motivo"
              value={value}
              label="Motivo"
              placeholder="Motivo"
              onChange={onChange}
            />
          )}
        </>
      ),
      size: { xs: !visualizar ? 0.25 : 12 },
    },
  ];

  return (
    <>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          <DynamicForm
            ref={formRef}
            fields={fields}
            initialValues={valores}
          />

          {/* Botões de salvar/cancelar */}
          <Grid>
            <Box sx={{ flexGrow: 1, mt: "16px" }}>
              <Grid
                container
                spacing={1}
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    sx={{ gap: "8px" }}
                    style={{ fontFamily: "Rawline Medium" }}
                    onClick={() => window.history.back()}
                  >
                    Cancelar
                  </Button>
                </Grid>
                {visualizar ? (
                  <>
                    {!editar && (
                      <>
                        <Grid item>
                          <Button
                            variant="outlined"
                            href="/usuario/visualizar/historico-situacao"
                            sx={{ gap: "8px" }}
                            style={{ fontFamily: "Rawline Medium" }}
                          >
                            <Typography variant={"BUTTON TEXT"}>Histórico de ativação</Typography>
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="outlined"
                            href="/usuario/editar/"
                            sx={{ gap: "8px" }}
                            style={{ fontFamily: "Rawline Medium" }}
                          >
                            <Typography variant={"BUTTON TEXT"}>Editar</Typography>
                          </Button>
                        </Grid>
                      </>
                    )}
                    <Grid item>
                      <Button
                        variant="contained"
                        sx={{ gap: "8px" }}
                        style={{ fontFamily: "Rawline Medium" }}
                      >
                        <Typography variant={"BUTTON TEXT"}>Excluir</Typography>
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <Grid item>
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      sx={{ gap: "8px" }}
                      style={{ fontFamily: "Rawline Medium" }}
                      onClick={handleButtonClick}
                    >
                      <SaveIcon fontSize="small" />
                      <Typography variant={"BUTTON TEXT"}>Salvar</Typography>
                    </LoadingButton>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        </Card>
      </Stack>
    </>
  );
};

AdminUsuario.propTypes = {
  editar: PropTypes.bool,
  visualizar: PropTypes.bool,
  valores: PropTypes.object,
  setValores: PropTypes.func,
};

export default AdminUsuario;
